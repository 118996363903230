.create-container-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: baseline;
    left: 0px;
    min-width: 100%;
    zoom: reset;

    .create-container-card-container {
        height: 897px;
        width: 958px;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .create-container-card2 {
        width: 958px;
        height: 897px;
        background: #393939 0% 0% no-repeat padding-box;
        border-radius: 6px;
        opacity: 1;
        position: relative;
        display: inline-block;
        font-size: 16px;
    
        .create-container-header {
            position: absolute; 
            text-align: left;
            font-weight: bold;
            font-size: 24px;
            color: whitesmoke;
        }

        .general-information {
            top: 25px;
            left: 25px;
        }

        .devices {
            top: 259px;
            left: 25px;
        }

        .miscellaneous {
            top: 543px;
            left: 25px;
        }

        .name-input-container {
            .name-input-label {
                position: absolute;
                top: 65px;
                left: 25px;
                text-align: left;
                color: whitesmoke;
            }

            .name-input {
                position: absolute;
                top: 90px;
                left: 25px;
                height: 32px;
                width: 429px;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                color: whitesmoke;
            }

            .name-input-error {
                position: absolute;
                top: 120px;
                left: 25px;
                color: $form-error-message-color;
                font-size: 0.8em;
            }
        }

        .select-location-container {
            .select-location-label {
                position: absolute;
                top: 142px;
                left: 25px;
                text-align: left;
                color: whitesmoke;
            }

            .location-output {
                position: absolute;
                top: 173px;
                left: 25px;
                height: 46px;
                width: 378px;
                text-align: left;
                font-style: italic;
                letter-spacing: 1px;
                color: whitesmoke;
            }

            .select-location-button {
                position: absolute;
                top: 180px;
                left: 414px;
                height: 32px;
                width: 40px;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                color: whitesmoke;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .scale-container-container {
            .scale-label {
                position: absolute;
                top: 299px;
                left: 25px;
                text-align: left;
                color: whitesmoke;
            }

            .scale-placeholder-label {
                position: absolute;
                top: 331px;
                left: 25px;
                font-style: italic;
                letter-spacing: 1px;
                color: whitesmoke;
            }
        }

        .label-printer-container-container {
            .label-printer-label {
                position: absolute;
                top: 376px;
                left: 25px;
                text-align: left;
                color: whitesmoke;
            }

            .label-printer-placeholder-label {
                position: absolute;
                top: 408px;
                left: 25px;
                font-style: italic;
                letter-spacing: 1px;
                color: whitesmoke;
            }
        }

        .birds-eye-view-camera-container-container {
            .birds-eye-view-camera-label {
                position: absolute;
                top: 453px;
                left: 25px;
                text-align: left;
                color: whitesmoke;
            }

            .birds-eye-view-camera-placeholder-label {
                position: absolute;
                top: 485px;
                left: 25px;
                font-style: italic;
                letter-spacing: 1px;
                color: whitesmoke;
            }
        }

        .description-textarea-container {
            .description-label {
                position: absolute;
                top: 65px;
                left: 502px;
                text-align: left;
                color: whitesmoke;
            }

            .description-input {
                position: absolute;
                top: 90px;
                left: 502px;
                height: 129px;
                width: 429px;
                text-align: left;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                color: whitesmoke;
            }
        }

        .arlo-camera-ids-container {
            .arlo-camera-ids-label {
                position: absolute;
                top: 299px;
                left: 502px;
                text-align: left;
                color: $text-color-forbidden;
            }

            .arlo-camera-input {
                position: absolute;
                top: 324px;
                left: 502px;
                height: 32px;
                width: 378px;
                text-align: left;
                background-color: $background-color-forbidden;
                border: 1px solid $text-color-forbidden;
                border-radius: 5px;
                color: $text-color-forbidden;
            }

            .arlo-camera-input-button {
                position: absolute;
                top: 324px;
                left: 891px;
                height: 32px;
                width: 40px;
                background-color: $background-color-forbidden;
                border: 1px solid $text-color-forbidden;
                border-radius: 5px;
                color: $text-color-forbidden;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .arlo-camera-ids-content {
                position: absolute;
                top: 366px;
                left: 502px;
                height: 150px;
                width: 429px;
                text-align: left;
                background-color: transparent;
                border: 1px solid transparent;
                border-radius: 5px;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                  width: 4px;
                  height: 4px;
                  cursor: pointer;
                }
          
                &::-webkit-scrollbar-thumb {
                  background: $tiny-scrollbar-color;
                  cursor: pointer;
          
                  &:hover {
                    background: $tiny-scrollbar-hover-color;
                  }
                }
            }

            .arlo-camera-ids-content-option {
                color: #f5f5f5;
                font-style: italic;
            }
        }

        .harvesting-procedures-container {
            .harvesting-procedures-label {
                position: absolute;
                top: 660px;
                left: 25px;
                text-align: left;
                color: whitesmoke;
            }

            .select-harvesting-procedures {
                position: absolute;
                top: 685px;
                left: 25px;
                height: 32px;
                width: 378px;
                text-align: left;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                color: whitesmoke;
            }

            .select-harvesting-procedures-button {
                position: absolute;
                top: 685px;
                left: 414px;
                height: 32px;
                width: 40px;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                color: whitesmoke;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .select-harvesting-procedures-content {
                position: absolute;
                top: 749px;
                left: 25px;
                height: 129px;
                width: 429px;
                text-align: left;
                background-color: transparent;
                border: 1px solid transparent;
                border-radius: 5px;
            }

            .select-harvesting-procedures-content-option {
                color: #f5f5f5;
                font-style: italic;
            }
        }

        .remove-button {
            margin-right: 10px;
            margin-bottom: 20px;
            cursor: pointer;
            transition-duration: 0.4s;
        
            &:hover {
                background-color: #5e5e5e;
            }
        }

        .farmhand-id-input-container {
            .farmhand-id-label {
                position: absolute;
                top: 583px;
                left: 25px;
                text-align: left;
                color: whitesmoke;
            }

            .farmhand-id-input {
                position: absolute;
                top: 608px;
                left: 25px;
                height: 32px;
                width: 429px;
                text-align: left;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                color: whitesmoke;
            }
        }

        .storage-advice-root-input-container {
            .storage-advice-root-label {
                position: absolute;
                top: 660px;
                left: 502px;
                text-align: left;
                color: whitesmoke;
            }

            .storage-advice-root-input {
                position: absolute;
                top: 685px;
                left: 502px;
                height: 78px;
                width: 429px;
                text-align: left;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                color: whitesmoke;
            }

            &:has(.storage-advice-root-input:disabled) {
                border: 1px solid $text-color-forbidden;
                border-radius: 5px;
                color: $text-color-forbidden;        
                
                .storage-advice-root-label,
                .storage-advice-root-input,
                i {
                    background-color: inherit; 
                    border-color: inherit;
                    color: inherit;
                }
            }
        }

        .storage-advice-cut-input-container {
            
            .storage-advice-cut-label {
                position: absolute;
                top: 771px;
                left: 502px;
                text-align: left;
                color: whitesmoke;

                i {
                    color: inherit;
                }
            }

            .storage-advice-cut-input {
                position: absolute;
                top: 796px;
                left: 502px;
                height: 78px;
                width: 429px;
                text-align: left;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                color: whitesmoke;
            }

            &:has(.storage-advice-cut-input:disabled) {
                border: 1px solid $text-color-forbidden;
                border-radius: 5px;
                color: $text-color-forbidden;
        
                .storage-advice-cut-label,
                .storage-advice-cut-input,
                i {
                    background-color: inherit; 
                    border-color: inherit;
                    color: inherit;
                }
            }
        }
    }
}