.harvesting-procedure-card-body {
    width: 260px;
    height: 141px;
    background: #464646 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    opacity: 1;
    cursor: pointer;
    position: relative;
}

.harvesting-procedure-card-image {
    top: 20.39px;
    left: 106px;
    width: 51px;
    height: 45px;
    filter: brightness(0) invert(1);
    opacity: 1;
    position: absolute;
}

.harvesting-procedure-card-text {
    top: 69.59px;
    left: 40px;
    right: 40px;
    text-align: center;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    font-family: 'Quicksand';
    letter-spacing: 0px;
    color: #F5F5F5;
    opacity: 1;
    position: absolute;
}