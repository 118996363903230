.produce-type-detail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: baseline;
    left: 0px;
    min-width: 100%;

    .produce-type-detail-card-container {
        height: 512px;
        width: 1024px;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .produce-type-detail-card {
        width: 958px;
        height: 512px;
        background: #393939 0% 0% no-repeat padding-box;
        border-radius: 6px;
        opacity: 1;
        position: relative;
        display: inline-block;

        .produce-type-detail-label {
            text-align: left;
            font-style: normal;
            font-variant: normal;
            font-size: 16px;
            line-height: 20px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #F5F5F5;
            opacity: 1;
            position: absolute;
        }

        .produce-type-detail-value {
            text-align: left;
            font-style: italic;
            font-variant: normal;
            font-size: 16px;
            line-height: 19px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #F5F5F5;
            opacity: 1;
            position: absolute;
        }

        .produce-type-detail-marketing-name {
            left: 25px;
            top: 25px;
            height: 20px;
        }

        .produce-type-detail-marketing-name-value {
            left: 25px;
            top: 57px;
            height: 18px;
        }

        .produce-type-detail-classification {
            left: 25px;
            top: 102px;
            height: 20px;
        }

        .produce-type-detail-classification-value {
            left: 25px;
            top: 134px;
            height: 18px;
        }

        .produce-type-detail-number {
            left: 25px;
            top: 179px;
            height: 20px;
        }

        .produce-type-detail-number-value {
            left: 25px;
            top: 211px;
            height: 18px;
        }

        .produce-type-detail-gtin {
            left: 254px;
            top: 179px;
            height: 20px;
        }

        .produce-type-detail-gtin-value {
            left: 254px;
            top: 211px;
            height: 18px;
        }

        .produce-type-detail-label-printer-template {
            left: 25px;
            top: 256px;
            height: 20px;
        }

        .produce-type-detail-label-printer-template-value {
            left: 25px;
            top: 288px;
            height: 18px;
        }

        .produce-type-detail-seed {
            left: 25px;
            top: 333px;
            height: 20px;
        }

        .produce-type-detail-seed-value {
            left: 25px;
            top: 364px;
            height: 18px;
        }

        .produce-type-detail-image {
            left: 502px;
            top: 25px;
            height: 20px;
        }

        .produce-type-detail-image-value {
            left: 502px;
            top: 50px;
            height: 186px;
            width: 429px;

            .produce-type-detail-image {
                position: absolute;
                top: 50px;
                left: 502px;
                width: 429px;
                height: 186px;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                color: whitesmoke;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .produce-type-detail-no-image-container {
                left: 502px;
                top: 50px;
                height: 186px;
                width: 429px;
                background: #535353 0% 0% no-repeat padding-box;
                border: 1px solid #F5F5F5;
                border-radius: 5px;
                opacity: 1;
                position: absolute;

                .produce-type-detail-no-image-icon {
                    top: 56px;
                    left: 172px;
                    width: 86px;
                    font-size: 75px;
                    color: #F5F5F5;
                    background: #535353 0% 0% no-repeat padding-box;
                    opacity: 1;
                    position: absolute;
                }
            }
        }

        .produce-type-detail-appetizer-text {
            left: 502px;
            top: 256px;
            height: 20px;
        }

        .produce-type-detail-appetizer-text-value {
            left: 502px;
            top: 288px;
            height: 18px;
        }

        .produce-type-detail-description {
            left: 502px;
            top: 333px;
            height: 20px;
        }

        .produce-type-detail-description-value {
            left: 502px;
            top: 363px;
            height: 75px;
            width: 419px;
            overflow: auto;
        }
    }
}