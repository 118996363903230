.transplanted-tray {
    display: grid;
    grid-template-columns: repeat(10, 30px);
    grid-gap: 2px;
    direction: rtl;

    .tray-item {
        background: $primary-color;
        height: 20px;
        @include flex-center;

        &[data-selected="true"] {
            background: forestgreen;
        }

        &-text {
            font-size: .8rem;
            color: whitesmoke;
            font-weight: 600;
            margin: 0;
        }
    }

    .mark {
        background: rgb(102, 102, 102)
    }
}