.container-management-container {
  height: 100%;
  width: 100%;
  display: flex;
  
  .container-list-overview {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 100%;    
  }
}

