.seed-type-card {
    width: 450px;
    height: 282px;
    background: #393939 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    position: relative;

    .seed-type-card-header {
        width: 450px;
        height: 101px;
        background: #535353 0% 0% no-repeat padding-box;
        border-radius: 5px 5px 0px 0px;
        opacity: 1;

        .seed-type-name-settings {
            top: 15px;
            left: 20px;
            height: 30px;
            font-size: 24px;
            color: #F5F5F5;
            position: absolute;
            
            input{
                opacity: 1;
                width: 315px;
                font-style: normal;
                font-variant: normal;
                font-weight: 700;
            }

            .error-banner {
                top: 37px;
            }
        }

        .seed-type-card-number {
            top: 25px;
            right: 25px;
            height: 30px;
            text-align: right;
            font-style: normal;
            font-variant: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #F5F5F5;
            opacity: 1;
            position: absolute;
        }

        .seed-type-scientificName-settings {
            top: 58px;
            left: 20px;
            text-align: left;
            position: absolute;

            input{
                width: 400px ;
            }

            .error-banner {
                top: 27px;
            }
        }
    }

    

    .seed-type-card-label {
        left: 25px;
        height: 20px;
        text-align: left;
        font-style: normal;
        font-variant: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        font-family: 'Quicksand';
        letter-spacing: 0px;
        color: #F5F5F5;
        opacity: 1;
        position: absolute;
    }

    .seed-type-commonName-settings {
        top: 110px;
        left: 20px;
        position: absolute;

        input {
            width: 400px;
        }
    
        .error-banner {
            top: 51px;
        }
    }

    .seed-type-seedsPerPlug-settings {
        top: 173px;
        left: 20px;
        position: absolute;

        input {
            width: 400px;
        }
    
        .error-banner {
            top: 51px;
        }
    }

    .seed-type-card-value {
        left: 25px;
        height: 18px;
        text-align: left;
        font-style: italic;
        font-variant: normal;
        font-size: 16px;
        line-height: 19px;
        font-family: 'Quicksand';
        letter-spacing: 0px;
        color: #F5F5F5;
        opacity: 1;
        position: absolute;
    }

    .seed-type-card-commonName.seed-type-card-value {
        top: 146px;
    }

    .seed-type-card-plug.seed-type-card-value {
        top: 209px;
    }

    .seed-type-card-button {
        height: 35px;
        top: 242px;
        position: absolute;
        text-align: center;
        font-style: normal;
        font-variant: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        font-family: 'Quicksand';
        letter-spacing: 0px;
        color: #f5f5f5;
        background: darken($primary-color, 5);
        opacity: 1;
        cursor: pointer;
    }

    .disabled {
        color: #b3b3b3;
    }

    .seed-type-card-delete.seed-type-card-button {
        width: 145px;
        left: 5px;
    }

    .seed-type-card-variants.seed-type-card-button {
        width: 145px;
        left: 150px;
    }

    .seed-type-card-edit.seed-type-card-button {
        width: 145px;
        left: 300px;
    }

    .seed-type-card-cancel.seed-type-card-button {
        width: 225px;
        left: 0px;
    }

    .seed-type-card-save.seed-type-card-button {
        width: 225px;
        left: 225px;
    }

    .seed-type-card-input {
        border: 1px solid $text-color;
        border-radius: 5px;
        background-color: #535353;
        color: $text-color;
    }

    .seed-type-card-name.seed-type-card-input {
        height: 42px;
        width: 330px;
        left: 22px;
        top: 19px;
    }

    .seed-type-card-scientificName.seed-type-card-input {
        top: 61px;
        left: 22px;
        height: 32px;
        width: 330px;
    }

    .seed-type-card-commonName.seed-type-card-value.seed-type-card-input {
        top: 139px;
        left: 22px;
        height: 32px;
        width: 330px;
    }

    .seed-type-card-error {
        left: 25px;
        color: red;
        position: absolute;
        font-style: normal;
        font-variant: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        font-family: 'Quicksand';
    }

    .seed-type-card-name-error.seed-type-card-error{
        top: 0px;
    }

    .seed-type-card-scientificName-error.seed-type-card-error{
        top: 85px;
    }

    .seed-type-card-commonName-error.seed-type-card-error{
        top: 165px;
    }
}