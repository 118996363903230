﻿.sweden-ui-container {
  padding: 0rem 1rem 8rem 1rem;
  margin: auto;
  position: absolute;
  top: 8rem;
  bottom: 0;
  left: 0;
  right: 0;
  // overflow: auto;
  touch-action: manipulation;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
}

@media (min-width: 1375px) {
  .sweden-ui-container {
    max-width: 75%;
  }
}

@media (min-width: 1075px) {
  .sweden-ui-container {
    max-width: 85%;
  }
}
