.destination-selection {
    padding: 1rem 1rem 6rem 1rem;
    height: 65vh !important;
    display: flex;
    justify-content: center;
    gap: 24px;
    align-items: center;
}

.destination-selection-card {
    width: 260px;
    height: 141px;
    background: #464646 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    opacity: 1;
    cursor: pointer;
    position: relative;

    &.selected {
        background: blue;
    }

    .fair2 {
        height: 3rem;
        width: 3rem;
        filter: invert(100%);
    }

    .destination-selection-card-text {
        top: 69.59px;
        left: 40px;
        right: 40px;
        text-align: center;
        font-style: normal;
        font-variant: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        font-family: 'Quicksand';
        letter-spacing: 0px;
        color: #F5F5F5;
        opacity: 1;
        position: absolute;
    }
}