.selector-center {
    justify-content: center !important; 
}

.entity-selector{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    // width: 65%;
    margin: auto auto;
    max-height: calc(100vh - 220px);
    
    //overflow-y: hidden;

    .entity-cards-container{
        max-width: 682px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        transition: 250ms all ease-out;
        margin-top: 150px;
        &[data-shrink="true"]{
            margin-top: 0px;
            .entity-card {
                height: 100px;
                width: 320px;
                font-size: 1.5rem;

                &:first-of-type {
                    margin-right: 10px;
                }
            }
        }

        .entity-card{
            @include tile;
        }
    }

    .numpad-container{
        // width: 100%;
        opacity: 0;
        transform: translateY(300%);
        transition: all 250ms ease-out;
        // position: absolute;
        // top: 200px;

        &[data-visible="true"]{
            opacity: 1;
            transform: none;
        }

        .sweden-ui-number-pad{
            max-width: unset;
        }
    }
}