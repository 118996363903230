.slot-unit {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $primary-color;
  color: $text-color;
  font-size: 2rem;
  height: 80px;
  border: 1px solid lighten($primary-color, 7);
  margin-bottom: 10px;

  .slot-number {
    position: absolute;
    font-size: 1rem;
    bottom: 2px;
    right: 5px;
  }

  .tray-name {
    font-size: 0.7rem;
    position: absolute;
    top: 0px;
    left: 5px;
    background-color: transparent;
    color: whitesmoke;
    direction: ltr;

    .tray-grid-label {    
      font-size: 1rem;
      position: absolute;
      right: 5px;
      bottom: 0px;
    }
  }

  .tray-grid-label {    
    font-size: 1rem;
    position: absolute;
    right: 5px;
    bottom: 0px;
  }

  .seed-name {
    font-size: 0.7rem;
    position: absolute;
    top: 0px;
    left: 5px;
    background-color: transparent;
    color: $text-color;
    font-weight: 700;
    direction: ltr;
  }

  .plant-icon {
    font-size: 2.3rem;
    color: lightgrey;
    margin-bottom: 0px;
  }

  &.seed-slot-planting {
    &[data-selected='true'] {
      background: lighten($primary-color, 20);
      color: forestgreen;
      .tray-grid-label {
        color: whitesmoke !important;
      }

      .plant-icon {
        color: forestgreen;
        
      }

      &[data-being-deselected='true'] {
        position: relative;
        background: lighten($primary-color,10);
      }
      
    }

    &[data-selectable='false'] {
      &[data-selected='true'] {
        background: $primary-color;
      }

      .plant-icon {
        color: forestgreen;
      }
    }
  }

  &.seed-slot-confirm {
    &[data-occupied='true'] {
      .plant-icon {
        color: forestgreen;
      }
    }

    &[data-selected='true'] {
      background: lighten($primary-color, 20);

      .plant-icon {
        color: forestgreen;
      }
    }
  }

  &.seed-slot-discarding {
    &[data-selected='true'] {
      background: lighten($primary-color, 20);

      &[data-being-deselected='true'] {
        position: relative;
        background: lighten($primary-color,10);
      }
    }

    &[data-selectable='true'] {
      .plant-icon {
        color: forestgreen;
      }
    }
  }

  &.seed-slot-discard-confirm {
    &[data-occupied='true'] {
      .plant-icon {
        color: forestgreen;
      }
    }
    
    &[data-selected='true'] {
      .plant-icon {
        color: red;
      }

      &[data-being-deselected='true'] {
        position: relative;
        background: lighten($primary-color,10);     
      }
    }
  }
}
