.tooltip-container {
  .tooltip-body {
    @include absolute-center;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;
    z-index: 100;
    margin: auto;
    width: 100%;
    max-height: 80%;
  }

  .seed-button {
    position: relative;
    z-index: 98;

    .number-badge {
      color: whitesmoke;
      border-radius: 50%;
      margin: auto;
      border: 1px solid $secondary-color;
      position: absolute;
      right: 5px;
      top: 5px;
      width: 30px;
      height: 30px;
      display: grid;
      vertical-align: middle;
      text-align: center;
      align-items: center;
      align-content: center;
      background-color: $primary-color;
      z-index: 98;
    }
  }
}
