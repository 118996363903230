.transplanted-panel {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-self: flex-start;
    width: 70%;
    align-self: auto;

    .panel-item {
        height: 50px;
        background: $primary-color;
        margin: 0 0;
        display: grid;
        place-content: center;
        height: 2rem;
        border-right: 1px solid whitesmoke;

        .panel-item-icon {
            color: forestgreen;
            margin: auto;
        }

        &[data-selected="true"] {
            background: forestgreen;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;

            .panel-item-text {
                color: whitesmoke;
                margin: 0;
            }            
        }
    }
}