.container-column {
  height: 6em;
  overflow-y: auto; 

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: $tiny-scrollbar-color;
    cursor: pointer;

    &:hover {
      background: $tiny-scrollbar-hover-color;
    }
  }
}

.user-column {
  height: 6em;
  overflow-y: auto; 
  
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: $tiny-scrollbar-color;
    cursor: pointer;

    &:hover {
      background: $tiny-scrollbar-hover-color;
    }
  }
}