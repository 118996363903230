.button-dropdown-ticket-information {
  .dropdown-menu-ticket-information {
    max-height: 800px;
    width: 360px;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
      background: $tiny-scrollbar-color;
      cursor: pointer;

      &:hover {
        background: $tiny-scrollbar-hover-color;
      }
    }

    .ticket-information {
      width: 320px;
      height: 60px;
      color: $primary-color;

      &.mark-as-new {
        font-weight: bold;
      }

      .summary {
        font-size: 16px;
        font-family: 'quicksand';
        margin-bottom: 5px;
        position: relative;
        top: -35px;
        left: 45px;
        max-width: 270px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        max-height: 3em;
        line-height: 1.2;
        text-decoration: underline;
        text-decoration-style: dotted;
        text-decoration-color: $primary-color;
        text-decoration-thickness: from-font;
      }

      .details {
        position: relative;
        font-size: 12px;
        font-family: 'Quicksand';
        left: 45px;
        top: -43px;
      }

      .ticket-icon {
        font-size: 35px;
        margin-right: 10px;
        position: relative;
        top: 2px;
      }
    }
  }
  .dropdown-toggle {
    position: relative; // Required for positioning the badge
  }

  .notification-badge {
    position: absolute;
    top: 1px; // Adjust based on your design
    right: 5px; // Adjust based on your design
    width: 20px; // Size of the badge
    height: 20px; // Size of the badge
    border-radius: 50%; // Makes the badge circular
    background-color: red; // Red background for the badge
    color: white; // Text color
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px; // Font size of the number
    font-weight: bold; // Font weight for better visibility
    box-shadow: 0 0 3px rgba(0,0,0,0.2); // Optional shadow for better visibility
  }
}
