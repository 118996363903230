.panel-selection-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  // overflow-x: auto;
  margin: auto 0;

  .panel-selection-container-row {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 20px 0;
    width: 100%;

    .transformed-channel-block {
      height: 100%,
    }

    .plant-block {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: inherit;
      background: $primary-color;
      color: $text-color;
      margin-bottom: 1px;
      font-size: 2rem;
      position: relative;
      border-right: 2px solid white;

      .plant-icon{
        padding: 15px;
      }

      .plant-icon-text {
        @include plant-icon-text;
      }

      .plant-name {
        @include plant-name;
      }

      &[data-visible='false'] {
        display: none;
      }

      &[data-is-available='false'] {
        // background: $secondary-color;
        color: forestgreen;
      }

      &[data-selected='true'] {
        background: forestgreen;
      }

      .block-content-separator {
        margin: 0 6px;
      }
    }

    .slot-unit {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: $primary-color;
      color: $text-color;
      font-size: 2rem;
      height: 80px;
      border: 1px solid lighten($primary-color, 7);

      .plant-icon {
        font-size: 2.5rem;
        color: lightgrey;
        margin-bottom: 5px;

        &[data-available='false'] {
          color: forestgreen;
        }
      }

      span {
        font-size: 1.5rem;
        position: absolute;
        bottom: 5px;
        right: 5px;
      }

      &[data-selected='true'] {
        background: lighten($primary-color, 20);
      }
    }
  }
}


