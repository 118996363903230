.edit-produce-type-container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: baseline;
    left: 0px;
    min-width: 100%;

    .edit-produce-type-container {
        height: 512px;
        width: 1024px;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .edit-produce-type-card {
        width: 958px;
        height: 512px;
        background: #393939 0% 0% no-repeat padding-box;
        border-radius: 6px;
        opacity: 1;
        position: relative;
        display: inline-block;


        .marketing-name-input-container {
            .marketing-name-input-label {
                position: absolute;
                top: 25px;
                left: 25px;
                height: 20px;
                width: 123px;
                text-align: left;
                font-style: normal;
                font-variant: normal;
                font-size: 16px;
                line-height: 20px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }

            .marketing-name-input {
                position: absolute;
                top: 50px;
                left: 25px;
                width: 429px;
                height: 32px;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                font-style: italic;
                font-variant: normal;
                font-size: 16px;
                line-height: 19px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }

            .marketing-name-error {
                position: absolute;
                top: 83px;
                left: 25px;
                color: lighten(red, 30);
                font-size: 0.8em;
            }
        }

        .classification-input-container {
            .classification-input-label {
                position: absolute;
                top: 102px;
                left: 25px;
                height: 20px;
                width: 97px;
                text-align: left;
                font-style: normal;
                font-variant: normal;
                font-size: 16px;
                line-height: 20px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }

            .classification-input {
                position: absolute;
                top: 127px;
                left: 25px;
                width: 429px;
                height: 32px;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                font-style: italic;
                font-variant: normal;
                font-size: 16px;
                line-height: 19px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }

            .classification-error {
                position: absolute;
                top: 162px;
                left: 25px;
                color: lighten(red, 30);
                font-size: 0.8em;
            }
        }

        .produce-number-and-gtin-input-container {
            .produce-number-input-label {
                position: absolute;
                top: 179px;
                left: 25px;
                height: 20px;
                width: 125px;
                text-align: left;
                font-style: normal;
                font-variant: normal;
                font-size: 16px;
                line-height: 20px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }

            .produce-number-input {
                position: absolute;
                top: 204px;
                left: 25px;
                width: 200px;
                height: 32px;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                font-style: italic;
                font-variant: normal;
                font-size: 16px;
                line-height: 19px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }

            .produce-number-error {
                position: absolute;
                top: 238px;
                left: 25px;
                color: lighten(red, 30);
                font-size: 0.8em;
            }

            .gtin-input-label {
                position: absolute;
                top: 179px;
                left: 254px;
                height: 20px;
                width: 36px;
                text-align: left;
                font-style: normal;
                font-variant: normal;
                font-size: 16px;
                line-height: 20px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }

            .gtin-input {
                position: absolute;
                top: 204px;
                left: 254px;
                width: 200px;
                height: 32px;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                font-style: italic;
                font-variant: normal;
                font-size: 16px;
                line-height: 19px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }

            .gtin-error {
                position: absolute;
                top: 238px;
                left: 254px;
                color: lighten(red, 30);
                font-size: 0.8em;
            }
        }

        .template-container {


            .template-label {
                position: absolute;
                top: 256px;
                left: 25px;
                height: 20px;
                width: 100%;
                text-align: left;
                font-style: normal;
                font-variant: normal;
                font-size: 16px;
                line-height: 20px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }

            .template-label-forbidden {
                position: absolute;
                top: 256px;
                left: 25px;
                height: 20px;
                width: 100%;
                text-align: left;
                font-style: normal;
                font-variant: normal;
                font-size: 16px;
                line-height: 20px;
                font-family: 'Quicksand';
                color: $text-color-forbidden;
            }

            .template-select {
                position: absolute !important;
                top: 281px !important;
                left: 25px !important;
                width: 429px !important;
                height: 32px !important;
                background-color: $text-input-background-color !important;
                border: 1px solid whitesmoke !important;
                border-radius: 5px !important;
                font-style: italic;
                color: $text-color;
            }

            .template-select-forbidden {
                position: absolute !important;
                top: 281px !important;
                left: 25px !important;
                width: 429px !important;
                height: 32px !important;
                background-color: $background-color-forbidden !important;
                border: 1px solid $text-color-forbidden !important;
                border-radius: 5px !important;
                font-style: italic;
                color: $text-color-forbidden;
            }
        }

        .seed-selection-container {
            .seed-selection-label {
                position: absolute;
                top: 333px;
                left: 25px;
                height: 20px;
                width: 80px;
                text-align: left;
                font-style: normal;
                font-variant: normal;
                font-size: 16px;
                line-height: 20px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }

            .seed-selection-label-forbidden {
                position: absolute;
                top: 333px;
                left: 25px;
                height: 20px;
                width: 80px;
                text-align: left;
                font-style: normal;
                font-variant: normal;
                font-size: 16px;
                line-height: 20px;
                font-family: 'Quicksand';
                color: $text-color-forbidden;
            }

            .seed-selection-display {
                position: absolute;
                top: 358px;
                left: 25px;
                width: 374px;
                height: 32px;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                font-style: italic;
                font-variant: normal;
                font-size: 16px;
                line-height: 19px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }

            .seed-selection-display-forbidden {
                position: absolute;
                top: 358px;
                left: 25px;
                width: 374px;
                height: 32px;
                background-color: $background-color-forbidden;
                border: 1px solid $text-color-forbidden;
                border-radius: 5px;
                font-style: italic;
                font-variant: normal;
                font-size: 16px;
                line-height: 19px;
                font-family: 'Quicksand';
                color: $text-color-forbidden;
            }

            .seed-selection-button {
                position: absolute;
                top: 358px;
                left: 412px;
                width: 40px;
                height: 32px;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                color: #F5F5F5;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .seed-selection-button-forbidden {
                position: absolute;
                top: 358px;
                left: 412px;
                width: 40px;
                height: 32px;
                background-color: $background-color-forbidden;
                border: 1px solid $text-color-forbidden;
                border-radius: 5px;
                color: #F5F5F5;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $text-color-forbidden;
            }
        }

        .produce-image-upload-container {
            .produce-image-upload-label {
                position: absolute;
                top: 25px;
                left: 502px;
                width: 100%;
                height: 20px;
                font-style: normal;
                font-variant: normal;
                font-size: 16px;
                line-height: 20px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }

            .produce-image-upload-label-forbidden {
                position: absolute;
                top: 25px;
                left: 502px;
                width: 100%;
                height: 20px;
                font-style: normal;
                font-variant: normal;
                font-size: 16px;
                line-height: 20px;
                font-family: 'Quicksand';
                color: $text-color-forbidden;
            }

            .produce-image-upload-display {
                position: absolute;
                top: 50px;
                left: 502px;
                width: 429px;
                height: 186px;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                color: #F5F5F5;
                display: flex;
                justify-content: center;
                align-items: center;

                .take-button {
                    position: absolute !important;
                    width: 86px;
                    height: 86px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $text-input-background-color;
                    font-size: 76px;

                    i {
                        font-size: inherit;
                    }
                }
            }

            .produce-image-upload-display-forbidden {
                position: absolute;
                top: 50px;
                left: 502px;
                width: 429px;
                height: 186px;
                background-color: $background-color-forbidden;
                border: 1px solid $text-color-forbidden;
                border-radius: 5px;
                color: $text-color-forbidden;
                display: flex;
                justify-content: center;
                align-items: center;

                .take-button {
                    position: absolute !important;
                    width: 86px;
                    height: 86px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $background-color-forbidden;
                    font-size: 76px;

                    i {
                        font-size: inherit;
                    }
                }
            }

            .saved-image {
                position: absolute;
                top: 50px;
                left: 502px;
                width: 429px;
                height: 186px;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                color: #F5F5F5;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .saved-image-forbidden {
                position: absolute;
                top: 50px;
                left: 502px;
                width: 429px;
                height: 186px;
                background-color: $background-color-forbidden;
                border: 1px solid $text-color-forbidden;
                border-radius: 5px;
                color: $text-color-forbidden;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .appetizer-input-container {
            .appetizer-input-label {
                position: absolute;
                top: 256px;
                left: 502px;
                height: 20px;
                width: 105px;
                text-align: left;
                font-style: normal;
                font-variant: normal;
                font-size: 16px;
                line-height: 20px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }

            .appetizer-input {
                position: absolute;
                top: 281px;
                left: 502px;
                width: 429px;
                height: 32px;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                font-style: italic;
                font-variant: normal;
                font-size: 16px;
                line-height: 19px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }
        }

        .description-input-container {
            .description-input-label {
                position: absolute;
                top: 333px;
                left: 502px;
                height: 20px;
                width: 148px;
                text-align: left;
                font-style: normal;
                font-variant: normal;
                font-size: 16px;
                line-height: 20px;
                font-family: 'Quicksand';
                color: #F5F5F5;
            }

            .description-input {
                position: absolute;
                top: 358px;
                left: 502px;
                width: 429px;
                height: 100px;
                background-color: $text-input-background-color;
                border: 1px solid whitesmoke;
                border-radius: 5px;
                font-style: italic;
                letter-spacing: 0px;
                color: #F5F5F5;
            }
        }
    }

    .retake-button {
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 50%;
        background-color: $primary-color;
        border: 1px solid whitesmoke;
        color: #F5F5F5;
        opacity: 0.7;
    }

    .forbidden {
        color: $text-color-forbidden !important;
    }

    .forbidden-input {
        color: $text-color-forbidden !important;
        background-color: $background-color-forbidden !important;
        border: 1px solid $text-color-forbidden !important;
    }
}