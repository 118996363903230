.wall {
    min-height: inherit;
    .wall-container {
      min-height: 500px;
      overflow: auto;
      display: grid;
      grid-template-columns: repeat(22, 1fr);
      place-items: center;
      grid-gap: 10px;

      .wall-unit {
        height: 100%;
        width: 70px;
        position: relative;
        background: $primary-color;
  
        &[data-occupied="true"],
        &[data-selected="true"]{
          .display-circle{
            background: forestgreen;
          }
        }

        &[data-selected='true'] {
            background: lighten($primary-color, 20);
          }
  
        .display-circle {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: $secondary-color;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $text-color;
        }
      }
  

    }
  }
  