.scope-option-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;

  .btn {
    width: 280px;
    border-radius: 15px;
    height: 180px;
    margin: 1rem;
    font-size: 1.7rem;

    .sweden-button-icon {
      margin: 0;
    }
  }
}
