.red-text {
  color: lightcoral !important;
}

.green-text {
  color: lightgreen !important;
}


.reactstrap-confirm {
  background-color: $text-color !important;
  position: relative;
  border: 5px solid $primary-color !important;

  .modal-content {
    background-color: $primary-color;
    color: whitesmoke;

    .modal-footer {
      color: whitesmoke;

      .btn-muted {
        background-color: $text-color;
        border: 1px solid $text-color;
        color: darken($primary-color, 20);
        font-weight: 700;
      }

      .btn-success {
        background-color: lightsalmon;
        color: darken($primary-color, 20);
        font-weight: 900;
        border: 1px solid lightsalmon;
      }
    }
  }
}

.multiharvestable-bool-container {
  height: 100% !important;
  place-content: center;
  /* margin: 0px 0px 0px 0px; */
  display: flex;
  grid-template-columns: 1fr 1fr;
  justify-content: left;
  align-items: baseline;
  font-size: 1.5rem;
}

#seed-management {
  align-self: flex-start;
}

.seed-management-container {
  padding: 1rem 1rem 6rem 1rem;
  height: 60vh !important;
  display: flex;
  justify-content: center;

  .seed-list-overview{
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }

  .main {
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .seed-card {
    color: $text-color;
    background: darken($primary-color, 5);
    height: auto;
    width: 100%;
    max-width: 450px;    
    min-height: 295px;

    .template-name-error-color{
      color: #FF9999;
    }

    &.add-new-seed-form {
      max-width: 90%;
      margin: auto;
      grid-gap: 15px;
    }

    .add-seed-symbol {
      display: block;
      margin: auto;
      cursor: pointer;
    }

    .h4-input {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .seed-property-input-field {
      border-radius: 5px;
      line-height: 1;
      padding: 6px;
      width: 100%;
      color: whitesmoke;
      background: lighten($primary-color, 5);
      outline: none;
      border: none;
      margin: 2px 0;
      border: 1px solid whitesmoke;

      &.in-add {
        width: unset;
      }

      &.invalid-field {
        border: 2px solid red;
      }

      &.header-input {
        // background: darken($primary-color, 2);
        background: $primary-color;
      }

      &::placeholder {
        color: lighten(darkgrey, 5);
        font-style: italic;
      }

      &[disabled] {
        border-color: transparent;
        background: none;
        opacity: 1;
        -webkit-appearance: none;
        -moz-appearance: none;
        // text-indent: 1px;
        // text-overflow: '';

        option {
          background: none;
          color: whitesmoke !important;
        }
      }
    }

    .card-body-input-row {

      &.add-row {
        margin-right: 10px;

        p {
          border: 1px solid whitesmoke;
          border-radius: 5px;
          padding: 5px;
          font-size: 0.7rem;
          margin: auto;
        }
      }

      &.span-row {
        // grid-column-start: 1;
        // grid-column-end: 2;
        column-span: all;
      }
      
      .input-label {
        display: block;
        margin: 0;
        padding-left: 6px;
        font-weight:700;

        &.thin {
          font-weight: unset;
        }
      }

      .seed-property-input-field {
        font-style: italic;
      }
    }
    .seed-card-header {
      background-color: lighten($primary-color, 5);
      padding: 0.5rem 1.25rem !important;

      h4 {
        font-weight: 700;
      }
    }

    .seed-card-body {
      height: max-content;
      padding: 0.5rem 1.25rem !important;

      &.add-new-seed-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap:20px;
      }
    }

    .seed-card-footer {

      .seed-card-text {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    .card-button {
      display: flex;

      .seed-card-btn {
        margin: 5px 5px;
        padding: 5px;
        background: darken($primary-color, 5);
      }
    }

    .card-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.invalid {
  background-color: $text-color;
  color: $primary-color;
  border: 1px solid $primary-color;
  border-radius: 5px;
  font-weight: 700;
  margin-top: 5px;
  padding: 5px;
  font-size: 0.7rem;
}

.add-seed-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65vh;
}

.back {
  float: right;
  background: $primary-color;
  &:hover {
    color: $text-color !important;
    background: lighten($primary-color, 5) !important;
  }
}

.seed-form {
  background-color: #393939 !important;
  color: whitesmoke !important;
  padding: 20px;

  select,
  input {
    background-color: #535353 !important;
    color: whitesmoke !important;
  }

  .seed-management-btn {
    background: $primary-color !important;
    color: whitesmoke !important;
    &:hover {
      color: $text-color !important;
      background: lighten($primary-color, 5) !important;
    }
  }
}