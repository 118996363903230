.seed-type-management-container {
    padding: 1rem 1rem 6rem 1rem;
    height: 65vh !important;
    display: flex;
    justify-content: center;

    .seed-type-create {
        width: 450px;
        height: 282px;
        background: #393939 0% 0% no-repeat padding-box;
        border-radius: 6px;
        opacity: 1;
        cursor: pointer;
        position: relative;

        .seed-type-create-icon {
            left: 210px;
            top: 126px;
            width: 30px;
            height: 30px;
            color: #F5F5F5;
            font-size: 30px;
            opacity: 1;
            align-items: center;
            display: flex;
            justify-content: center;
            position: absolute;
        }
    }

    .seed-type-list {
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        width: 100%;
    }
}