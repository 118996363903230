.edit-container-container-wrapper {
  display: flex;
  justify-content: center;
  align-self: baseline;
  left: 0px;
  min-width: 100%;
  zoom: reset;

  .disable {
    color: $text-color-forbidden !important;
  }

  .edit-container-container {
    align-items: center;
    display: flex;
    width: 958px;
    height: 887px;
    background: #393939 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    position: relative;

    .edit-container-title {
      position: absolute;
      left: 25px;
      top: 25px;
      font-size: 24px;
      color: $text-color;
      font-weight: 700;
    }

    .container-name-icon {
      position: absolute;
      left: 30px;
      top: 67px;
      color: $text-color;
    }

    .container-name-label {
      position: absolute;
      left: 55px;
      top: 67px;
      color: $text-color;
    }

    .container-name-input {
      position: absolute;
      top: 90px;
      left: 25px;
      width: 429px;
      height: 34px;
      border: 1px solid $text-color;
      border-radius: 5px;
      background-color: #535353;
      font-style: italic;
      color: $text-color;
    }

    .container-location-icon {
      position: absolute;
      left: 30px;
      top: 144px;
      color: $text-color;
    }

    .container-location-label {
      position: absolute;
      left: 55px;
      top: 142px;
      color: $text-color;
    }

    .container-location-details {
      position: absolute;
      top: 173px;
      left: 25px;
      color: $text-color;
      width: 378px;
      height: 46px;
      font-size: 16px;
      font-style: italic;
    }

    .container-location-popup-button {
      position: absolute;
      top: 173px;
      left: 414px;
      height: 32px;
      width: 40px;
      background-color: #535353;
      color: $text-color;
      border: 1px solid $text-color;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .container-devices-title {
      position: absolute;
      top: 259px;
      left: 25px;
      color: $text-color;
      font-size: 24px;
      font-weight: 700;
    }

    .container-scale-icon {
      position: absolute;
      top: 299px;
      left: 30px;
      color: $text-color;
    }

    .container-scale-label {
      position: absolute;
      top: 299px;
      left: 55px;
      color: $text-color;
    }

    .container-scale-id-display {
      position: absolute;
      top: 331px;
      left: 25px;
      width: 380px;
      color: $text-color;
      font-style: italic;
    }

    .container-scale-id-copy-icon {
      margin-left: 5px;
      color: $text-color;
      cursor: pointer;
    }

    .container-labelprinter-icon {
      position: absolute;
      top: 377px;
      left: 30px;
      color: $text-color;
    }

    .container-labelprinter-label {
      position: absolute;
      top: 377px;
      left: 55px;
      color: $text-color;
    }

    .container-labelprinter-id-display {
      position: absolute;
      top: 409px;
      left: 25px;
      width: 380px;
      color: $text-color;
      font-style: italic;
      font-size: 16px;
      letter-spacing: 1px;
    }

    .container-labelprinter-id-copy-icon {
      margin-left: 5px;
      color: $text-color;
      cursor: pointer;
    }

    .container-bev-camera-icon {
      position: absolute;
      top: 455px;
      left: 30px;
      color: $text-color;
    }

    .container-bev-camera-label {
      position: absolute;
      top: 455px;
      left: 55px;
      width: 176px;
      color: $text-color;
    }

    .container-bev-camera-disabled-icon {
      position: absolute;
      top: 455px;
      left: 237px;
      color: red;
    }

    .container-bev-camera-enabled-icon {
      position: absolute;
      top: 455px;
      left: 237px;
      color: $text-color;
    }

    .container-bev-camera-settings {
      position: absolute;
      top: 478px;
      left: 414px;
      height: 32px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $text-color;
      border: 1px solid $text-color;
      border-radius: 5px;
      background-color: $text-input-background-color;
      cursor: pointer;
    }

    .container-bev-camera-id-display {
      position: absolute;
      top: 485px;
      left: 25px;
      width: 380px;
      color: $text-color;
      font-style: italic;
      letter-spacing: 1px;
    }

    .container-bev-camera-id-copy-icon {
      margin-left: 5px;
      color: $text-color;
      cursor: pointer;
    }

    .container-misc-title {
      position: absolute;
      top: 543px;
      left: 25px;
      font-size: 24px;
      font-weight: 700;
      color: $text-color;
    }

    .container-farmhand-icon {
      position: absolute;
      top: 585px;
      left: 30px;
      color: $text-color;
    }

    .container-farmhand-label {
      position: absolute;
      top: 585px;
      left: 55px;
      width: 97px;
      color: $text-color;
    }

    .container-farmhand-id-input {
      position: absolute;
      top: 609px;
      left: 25px;
      width: 429px;
      height: 32px;
      font-style: italic;
      color: $text-color;
      background-color: $text-input-background-color;
      border: 1px solid $text-input-border-color;
      border-radius: 5px;
    }

    .container-root-advice-icon {
      position: absolute;
      top: 662px;
      left: 507px;
      color: $text-color;
    }

    .container-root-advice-label {
      position: absolute;
      top: 662px;
      left: 532px;
      color: $text-color;
    }

    .container-root-advice-input {
      position: absolute;
      top: 686px;
      left: 507px;
      width: 429px;
      height: 78px;
      font-style: italic;
      color: $text-color;
      background-color: $text-input-background-color;
      border: 1px solid $text-input-border-color;
      border-radius: 5px;

      &:disabled {
        border: 1px solid $text-color-forbidden;
        border-radius: 5px;
        color: $text-color-forbidden;
      }

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb {
        background: $tiny-scrollbar-color;
        cursor: pointer;

        &:hover {
          background: $tiny-scrollbar-hover-color;
        }
      }
    }

    .container-cut-advice-icon {
      position: absolute;
      top: 771px;
      left: 507px;
      color: $text-color;
    }

    .container-cut-advice-label {
      position: absolute;
      top: 771px;
      left: 532px;
      color: $text-color;
    }

    .container-cut-advice-input {
      position: absolute;
      top: 796px;
      left: 507px;
      width: 429px;
      height: 78px;
      font-style: italic;
      color: $text-color;
      background-color: $text-input-background-color;
      border: 1px solid $text-input-border-color;
      border-radius: 5px;

      &:disabled {
        border: 1px solid $text-color-forbidden;
        border-radius: 5px;
        color: $text-color-forbidden;
      }

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb {
        background: $tiny-scrollbar-color;
        cursor: pointer;

        &:hover {
          background: $tiny-scrollbar-hover-color;
        }
      }
    }

    .container-description-icon {
      position: absolute;
      top: 67px;
      left: 509px;
      color: $text-color;
    }

    .container-description-label {
      position: absolute;
      top: 67px;
      left: 534px;
      color: $text-color;
    }

    .container-description-input {
      position: absolute;
      top: 90px;
      left: 502px;
      width: 429px;
      height: 129px;
      font-style: italic;
      color: $text-color;
      background-color: $text-input-background-color;
      border: 1px solid $text-input-border-color;
      border-radius: 5px;
    }

    .container-camera-icon {
      position: absolute;
      top: 301px;
      left: 509px;
      color: $text-color-forbidden;
    }

    .container-camera-label {
      position: absolute;
      top: 301px;
      left: 534px;
      color: $text-color-forbidden;
    }

    .container-camera-input {
      position: absolute;
      top: 324px;
      left: 502px;
      width: 378px;
      height: 32px;
      font-style: italic;
      color: $text-color-forbidden;
      background-color: $background-color-forbidden;
      border: 1px solid $text-color-forbidden;
      border-radius: 5px;
    }

    .container-add-camera-button {
      position: absolute;
      top: 324px;
      left: 891px;
      height: 32px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $text-color-forbidden;
      background-color: $background-color-forbidden;
      border: 1px solid $text-color-forbidden;
      border-radius: 5px;
      cursor: pointer;
    }

    .container-camera-list {
      position: absolute;
      top: 382px;
      left: 502px;
      height: 120px;
      width: 429px;
      color: $text-color-forbidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb {
        background: $tiny-scrollbar-color;
        cursor: pointer;

        &:hover {
          background: $tiny-scrollbar-hover-color;
        }
      }

      .container-camera-id-item {
        display: flex;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 20px;
      }

      .container-camera-id {
        color: $text-color;
        font-style: italic;
        margin-right: 5px;
      }

      .container-camera-remove-icon {
        font-size: 16px;
        cursor: pointer;
      }
    }

    .container-procedures-icon {
      position: absolute;
      top: 664px;
      left: 30px;
      color: $text-color;
    }

    .container-procedures-label {
      position: absolute;
      top: 664px;
      left: 55px;
      color: $text-color;
    }

    .container-procedures-dropdown {
      position: absolute;
      top: 687px;
      left: 25px;
      height: 32px;
      width: 380px;
      text-align: left;
      background-color: $text-input-background-color;
      border: 1px solid whitesmoke;
      border-radius: 5px;
      color: whitesmoke;
    }

    .container-procedures-add-button {
      position: absolute;
      top: 687px;
      left: 413px;
      height: 32px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $text-color;
      background-color: $text-input-background-color;
      border: 1px solid $text-input-border-color;
      border-radius: 5px;
    }

    .container-procedures-list {
      position: absolute;
      top: 749px;
      left: 25px;
      height: 140px;
      width: 429px;
      color: $text-color;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb {
        background: $tiny-scrollbar-color;
        cursor: pointer;

        &:hover {
          background: $tiny-scrollbar-hover-color;
        }
      }

      .container-procedures-item {
        display: flex;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 20px;
      }

      .container-procedure-name {
        color: $text-color;
        font-style: italic;
      }

      .container-procedures-remove-icon {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}
