.report-form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: auto;
  background: darken($primary-color, 5);
  
  .report-form-area {
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    .report-comment {
      padding-top: 1rem;
      display: flex;
      flex-direction: column;
      color: whitesmoke;
    }
    
    .comment-textarea {
      background: lighten($primary-color, 5);
      color: whitesmoke;
      padding: 1rem;
      width: 100%;
      outline: none;
      border: none;
      resize: none;
    }
  }

  #poca {
    display: none;
  }

  .upload-photo-btn, .file-select {
    height: 100% !important;
  }
  
  .report-category-dropdown {
    label {
      display: block;
      color: whitesmoke;
    }
    select {
      background: lighten($primary-color, 5);
      color: whitesmoke;
      display: block;
      padding: 0.3rem 0.4rem;
      width: 100%;
    }
  }

  .camera-output {
    display: flex;
    flex-direction: column;
    min-width: 500px;
    min-height: 429px !important;
    background-color: darken($primary-color, 5);
    color: whitesmoke;
    padding: 1rem;

    div#saved-image {
      min-height: 400px !important;
      min-width: 400px !important;
    }

    #btn-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      background: $primary-color;

      .btn{
        border-radius: 0%;
        background: darken($primary-color, 5);
      }
    }

    .video-placeholder {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: whitesmoke;
      background: $primary-color;
      font-size: 2.3rem;
    }

    .video-container {
      height: 100%;
      position: relative;

      #photo {
        // width: 100%;
        // height: 100%;
      }

      .flip-btn,
      .swap-cameras-btn {
        position: absolute;
        top: 3px;
        right: 3px;
        width: unset;
        border-radius: 50%;

        .sweden-button-icon {
          padding: 0;
          margin: 0;
        }

      }

      .swap-cameras-btn {
        top: 55px;
      }

      #video-stream {
        box-sizing: border-box;
        display: none;

        &[data-show='true'] {
          display: block;
          width: 100%;
        }
      }
    }

    #canvas {
      display: none;
      // height: 100%;
      // width: 100%;
    }
  }
}
