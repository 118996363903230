#info-panel{
    background: rgba(235, 235, 235, 1);
    color: $primary-color;
    padding: .5rem;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    min-width: 1024px;
}