.drop {
  position: relative;
}

.small {
  font-size: 1.0rem !important;
  font-weight: 600 !important;

  
}

.sweden-ui-input-button {
  outline: none;
  border: none;
  color: $text-color;
  background: $primary-color;
  cursor: pointer;
  text-align: center;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 0.7rem;
  display: flex;
  // display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // min-width: 160px;
  white-space: pre-wrap;
  

  &:hover {
    color: $text-color;
    background: lighten($primary-color, 5);
  }

  &:focus {
    box-shadow: none;
  }

  .sweden-button-icon {
    font-size: 1.3rem;

    .small {
      font-size: 1.3rem !important;
    }
  }

  &[data-type="inline"] {
    flex-direction: row;
    align-items: center;

    i {
      margin-right: 6px;
    }
  }

  &[data-disabled='true'] {
    background: lighten(grey,20) !important;
    color: lighten(rgb(46, 46, 46),20) !important;
    pointer-events: none;

  
  }

  &.reset-btn {
    background: lighten($primary-color, 10);
    color: $text-color !important;
    font-weight: 600;
    font-size: 1.6rem;
  }
}
