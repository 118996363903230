.sweden-status-bar {
  height: 70px;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  box-shadow: 0 3px 7px 0px grey;
  //background: #f5f5f5;
  color: $primary-color;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  place-items: center;
  background-color: transparent;
  width: 100%;
  min-width: 1024px;

  .btn {
    .sweden-button-icon {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  button {
    min-width: 160px;
  }

  .status-btn-5 {
    display: flex;
    position: relative;



    .dropdown-button {
      display: flex;
      // position: absolute;
      // right: -25px;
      padding: 0 9px;
      max-width: 30px;
      background-color:#464646;
      height: 55px;
      align-items: center;
      justify-content: center;
      border-left: 1px solid lighten(#464646,10);
      color: whitesmoke;
      border-top-right-radius: .25rem;
      border-bottom-right-radius: .25rem;

      
    }

    .dropdown-items {
      font-size: 0.8rem;
      
      justify-content: center;
      align-items: center;
      font-weight: 700;
      background: #464646;
      color: whitesmoke !important;

      .dropdown-item {
      @include flex-center;

        font-size: 1rem !important;
        color: whitesmoke; 
        background: #464646;       
        font-weight: 700;

        .item-text {
          margin: 0;
        }

        &:hover {
          color: $text-color;
          background: lighten($primary-color,10);
        }
      }

      // [data-disabled='false'] {
      //   color: #616161 !important;
      // }

      // &[data-disabled='false'] {
      //   background: lighten(grey, 20);
      //   // color: lighten(rgb(46, 46, 46), 20);
        

      //   :hover {
      //     background-color: lighten(gray,10);
      //   }
      // }      
    }
  }

  .btn {
    font-size: 1.5rem !important;
    // max-width: 160px !important;
    padding: 0.6rem 1rem !important;
    // display: block !important;

    height: 55px;
    flex-direction: row;
    align-items: center;



    // i {
    //   margin: 11px 6px 0 6px;
    // }

    &.reset-btn {
      box-shadow: 0 0 5px 0 grey;
    }

    &.reset-selection-btn {
      max-width: 230px !important;
      box-shadow: 0 0 5px 0 grey;
    }
  }
}
