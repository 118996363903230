#sweden-notification-container {
  position: fixed;
  bottom: -380px;
  right: 110px;
  display: flex;
  z-index: 55555555555556;
}

.sweden-notification {
  position: fixed;
  right: 1em;
  bottom: -10em;
  min-width: 17em;
  max-width: 400px;
  padding: 1rem 1.4rem;
  border-radius: 10px;
  text-align: center;
  color: #222;
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  border: 1px solid whitesmoke;
  z-index: 55555555555556;

  .sweden-notification-body {
    align-self: flex-start;
    width: 90%;

    a {
      color: #F5F5F5;
      text-decoration: none;
      border-bottom: 2px dotted;
    }
  }

  .close-notification-button {
    width: 1em;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 1.2em;
    align-self: flex-end;
    margin: 10px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  &[data-type='info'] {
    background: $info-color;
    z-index: 55555555555555;
    animation: average 5000ms ease-out forwards;
  }

  &[data-type='success'] {
    background: $success-color;
    color: whitesmoke;
    z-index: 555555555555556666 !important;
    animation: super-short 1500ms ease-out forwards;
  }

  &[data-type='error'] {
    background: $danger-color;
    color: whitesmoke;
    animation: long 9000ms ease-out forwards;
    z-index: 55555555555555;
  }

  &[data-type='info-red'] {
    background: $danger-color;
    animation: short 3000ms ease-out forwards;
    color: whitesmoke;
    z-index: 55555555555555;
  }

  &[data-type='error-stay'] {
    background: $danger-color;
    color: whitesmoke;
    z-index: 555555555555554;
    animation: toast-in 200ms ease-out forwards;

    .close-button-color {
      color: whitesmoke;
    }
  }

  &[data-type='info-stay'] {
    background: $info-color;
    color: whitesmoke;
    z-index: 555555555555554;
    animation: toast-in 200ms ease-out forwards;

    .close-button-color {
      color: whitesmoke;
    }
  }
}

@keyframes long {
  0% {
    bottom: -80px;
  }

  3% {
    bottom: 80px;
  }

  95% {
    bottom: 80px;
  }

  98% {
    bottom: 120px;
  }

  100% {
    bottom: -80px;
  }
}

@keyframes average {
  0% {
    bottom: -80px;
  }

  5% {
    bottom: 80px;
  }

  90% {
    bottom: 80px;
  }

  95% {
    bottom: 120px;
  }

  100% {
    bottom: -80px;
  }
}

@keyframes short {
  0% {
    bottom: -80px;
  }

  8% {
    bottom: 80px;
  }

  85% {
    bottom: 80px;
  }

  93% {
    bottom: 120px;
  }

  100% {
    bottom: -80px;
  }
}

@keyframes super-short {
  0% {
    bottom: -80px;
  }

  10% {
    bottom: 80px;
  }

  85% {
    bottom: 80px;
  }

  93% {
    bottom: 120px;
  }

  100% {
    bottom: -180px;
  }
}

@keyframes toast-in {
  0% {
    bottom: -80px;
  }

  90% {
    bottom: 80px;
  }

  100% {
    bottom: 80px;
  }
}