.add-new-seed-card {
  width: 910px;
  height: 592px;
  background: #393939 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  position: relative;

  .add-seed-type-title {
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    font-family: 'Quicksand';
    letter-spacing: 0px;
    color: #f5f5f5;
    opacity: 1;
    position: absolute;
  }

  .name-pos {
    position: absolute;
    top: 65px;
    left: 25px;
    width: 405px;
  }

  .scientific-name-pos {
    position: absolute;
    top: 65px;
    left: 480px;
    width: 405px;
  }

  .common-name-pos {
    position: absolute;
    width: 405px;
    top: 142px;
    left: 25px;
  }

  .number-pos {
    position: absolute;
    width: 405px;
    top: 142px;
    left: 480px;
  }

  .seeds-per-plug-pos {
    position: absolute;
    width: 405px;
    top: 219px;
    left: 25px;
  }

  .lot-pos {
    position: absolute;
    width: 405px;
    top: 356px;
    left: 25px;
  }

  .manufacturer-pos {
    position: absolute;
    width: 405px;
    top: 356px;
    left: 480px;
  }

  .seed-count-pos {
    position: absolute;
    width: 405px;
    top: 433px;
    left: 25px;
  }

  .package-weight-pos {
    position: absolute;
    width: 405px;
    top: 433px;
    left: 480px;
  }

  .tgw-pos {
    position: absolute;
    width: 405px;
    top: 510px;
    left: 25px;
  }

  .add-seed-type {
    top: 25px;
    left: 25px;
  }

  .add-seed-type-variant {
    top: 316px;
    left: 25px;
  }
}