#produce-type-management {
    align-self: flex-start; 
    overflow-x: hidden;
}

.produce-type-management-container {
    padding: 1rem 1rem 6rem 1rem;
    height: 65vh !important;
    display: flex;
    justify-content: center;

    .select-seed-type-container {
        @media only screen and (min-width: 460px) {
            width: 460px;
        }

        @media only screen and (min-width: 910px) {
            width: 910px;
        }

        @media only screen and (min-width: 1370px) {
            width: 1370px;
        }
    }

    .select-seed-type-accordion {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 10px;
    }

    .produce-type-accordion-title {
        text-align: left;
        font-style: normal;
        font-variant: normal;
        font-size: 16px;
        line-height: 20px;
        font-family: 'Quicksand';
        letter-spacing: 0px;
        color: #464646;
        opacity: 1;
        margin-bottom: 2vh;
        display: flex;
    }

    .select-seed-type-icon {
        width: 24px;
        text-align: center;
        margin-right: 5px;
    }

    .select-seed-type-list-active {
        margin: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        min-width: 450px;
        opacity: 1;
        transition: opacity 0.5s ease;
    }

    .select-seed-type-list-inactive {
        width: 100%;
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    .produce-type-list {
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        width: 100%;
    }

    .produce-type-card {
        color: $text-color;
        background: darken($primary-color, 5);
        height: auto;
        width: 100%;
        max-width: 450px;    
        min-height: 150px;

        .produce-type-property-paragraph {
            margin-bottom: 0rem;
        }

        .produce-type-property-input-field {
            border-radius: 5px;
            line-height: 1;
            width: 100%;
            color: whitesmoke;
            background: lighten($primary-color, 5);
            outline: none;
            border: none;
            border: 1px solid whitesmoke;

            &.in-add {
                width: unset;
            }

            &.invalid-field {
                border: 2px solid red;
            }

            &.header-input {
                background: $primary-color;
            }

            &::placeholder {
                color: lighten(darkgrey, 5);
                font-style: italic;
            }

            &[disabled] {
                border-color: transparent;
                background: none;
                opacity: 1;
                -webkit-appearance: none;
                -moz-appearance: none;

                option {
                    background: none;
                    color: whitesmoke !important;
                }
            }
        }

        .input-label {
            font-weight: 700;

            &.thin {
                font-weight: unset;
            }
        }

        .produce-type-card-header {
            background-color: lighten($primary-color, 5);
            padding: 1.5rem 1.25rem 0.5rem !important;

            .produce-type-card-header-upper {
                display: grid;
                grid-template-columns: 2fr 1fr;
            }

            h4 {
                font-weight: 700;
            }
        }

        .produce-type-card-body {
            height: max-content;
            padding: 1.0rem 1.25rem 1.0rem !important;
        }

        .produce-type-card-footer {
            .produce-type-card-text {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }

        .card-button {
            display: flex;

            .produce-type-card-btn {
                margin: 5px 5px;
                padding: 5px;
                background: darken($primary-color, 5);
            }
        }

        .add-produce-type-symbol {
            display: block;
            margin: auto;
            cursor: pointer;
        }
    }
}

.seed-type-card-outdated {
    width: 450px;
    height: 306px;
    background: #8e8e8e 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;

    .seed-type-card-header-outdated {
        width: 450px;
        height: 103px;
        background: #a3a3a3 0% 0% no-repeat padding-box;
        border-radius: 5px 5px 0px 0px;
        opacity: 1;
        position: relative;

        .seed-type-header-name {
            top: 25px;
            left: 25px;
            height: 30px;
            text-align: left;
            font-style: normal;
            font-variant: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            position: absolute;
        }

        .seed-type-header-number {
            text-align: right;
            font-style: normal;
            font-variant: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            font-family: 'Quicksand';
            top: 25px;
            right: 25px;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            position: absolute;
        }

        .seed-type-property-paragraph {
            top: 68px;
            left: 25px;
            height: 18px;
            text-align: left;
            font-style: italic;
            font-variant: normal;
            font-size: 16px;
            line-height: 19px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            position: absolute;
        }
    }

    .seed-type-card-body-outdated {
        position: relative;

        .seed-type-body-common-name-title {
            top: 15px;
            left: 25px;
            height: 20px;
            text-align: left;
            font-style: normal;
            font-variant: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            position: absolute;
        }

        .seed-type-body-common-name {
            top: 45px;
            left: 25px;
            height: 18px;
            text-align: left;
            font-style: italic;
            font-variant: normal;
            font-size: 16px;
            line-height: 19px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            position: absolute;
        }

        .seed-type-body-created-title {
            top: 78px;
            left: 25px;
            height: 20px;
            text-align: left;
            font-style: normal;
            font-variant: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            position: absolute;
        }

        .seed-type-body-created {
            top: 108px;
            left: 25px;
            height: 18px;
            text-align: left;
            font-style: italic;
            font-variant: normal;
            font-size: 16px;
            line-height: 19px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            position: absolute;
        }

        .seed-type-body-deleted-title {
            top: 141px;
            left: 25px;
            height: 20px;
            font-style: normal;
            font-variant: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            position: absolute;
        }

        .seed-type-body-deleted {
            top: 171px;
            left: 25px;
            height: 18px;
            text-align: left;
            font-style: italic;
            font-variant: normal;
            font-size: 16px;
            line-height: 19px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            position: absolute;
        }
    }
}

.seed-type-card {
    width: 450px;
    height: 243px;
    background: #393939 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;

    .seed-type-card-header {
        width: 450px;
        height: 103px;
        background: #535353 0% 0% no-repeat padding-box;
        border-radius: 5px 5px 0px 0px;
        opacity: 1;
        position: relative;

        .seed-type-header-name {
            top: 25px;
            left: 25px;
            height: 30px;
            text-align: left;
            font-style: normal;
            font-variant: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #F5F5F5;
            opacity: 1;
            position: absolute;
        }

        .seed-type-header-number {
            text-align: right;
            font-style: normal;
            font-variant: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            font-family: 'Quicksand';
            top: 25px;
            right: 25px;
            letter-spacing: 0px;
            color: #F5F5F5;
            opacity: 1;
            position: absolute;
        }

        .seed-type-property-paragraph {
            top: 68px;
            left: 25px;
            height: 18px;
            text-align: left;
            font-style: italic;
            font-variant: normal;
            font-size: 16px;
            line-height: 19px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #F5F5F5;
            opacity: 1;
            position: absolute;
        }
    }

    .seed-type-card-body {
        position: relative;

        .seed-type-body-common-name-title {
            top: 15px;
            left: 25px;
            height: 20px;
            text-align: left;
            font-style: normal;
            font-variant: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #F5F5F5;
            opacity: 1;
            position: absolute;
        }

        .seed-type-body-common-name {
            top: 45px;
            left: 25px;
            height: 18px;
            text-align: left;
            font-style: italic;
            font-variant: normal;
            font-size: 16px;
            line-height: 19px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #F5F5F5;
            opacity: 1;
            position: absolute;
        }

        .seed-type-body-created-title {
            top: 78px;
            left: 25px;
            height: 20px;
            text-align: left;
            font-style: normal;
            font-variant: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #F5F5F5;
            opacity: 1;
            position: absolute;
        }

        .seed-type-body-created {
            top: 108px;
            left: 25px;
            height: 18px;
            text-align: left;
            font-style: italic;
            font-variant: normal;
            font-size: 16px;
            line-height: 19px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #F5F5F5;
            opacity: 1;
            position: absolute;
        }
    }
}