.input-property-field {
    border-radius: 5px;
    line-height: 1;
    padding: 6px;
    width: 100%;
    color: whitesmoke;
    background: lighten($primary-color, 5);
    outline: none;
    border: none;
    margin: 2px 0;
    border: 1px solid whitesmoke;

    &.in-add {
        width: unset;
    }

    &.invalid-field {
        border: 2px solid #E30000;
    }
    
    &:focus {
        position: relative;
        z-index: 1000;
    }

    &.header-input {
        // background: darken($primary-color, 2);
        background: $primary-color;
    }

    &::placeholder {
        color: lighten(darkgrey, 5);
        font-style: italic;
    }

    &[disabled] {
        border-color: transparent;
        background: none;
        opacity: 1;
        -webkit-appearance: none;
        -moz-appearance: none;
        // text-indent: 1px;
        // text-overflow: '';

        option {
            background: none;
            color: whitesmoke !important;
        }
    }
}

.error-banner {
    background-color: #E30000;
    border-radius: 4px;
    position: absolute;
    z-index: 999;
    font-size: 12px;
    height: 30px;
    width: 100%;
    top: 50px;

    span {
        position: absolute;
        left: 10px;
        top: 10px;
        color: #f5f5f5;
    }
}

.card-body-input-row {

    &.add-row {
        margin-right: 10px; 

        p {
            border: 1px solid whitesmoke;
            border-radius: 5px;
            padding: 5px;
            font-size: 0.7rem;
            margin: auto;
        }
    }

    &.span-row {
        // grid-column-start: 1;
        // grid-column-end: 2;
        column-span: all;
    }
    
    // .input-label {
    // display: block;
    // margin: 0;
    // padding-left: 6px;
    // font-weight:700;
    // color: whitesmoke;

    //     &.thin {
    //         font-weight: unset;
    //     }
    // }

    .input-property-field {
        font-style: italic;
    }
}

.input-label {
    display: block;
    margin: 0;
    padding-left: 6px;
    font-weight:700;
    color: whitesmoke;

    &.thin {
        font-weight: unset;
    }
}
