.fai-circle-check {
  background-image: url('../../../public/assets/icons/circle-check/circle-check-regular.svg');
  background-size: cover;
  text-rendering: auto; 
}

.fai-select-all {
  background-image: url('../../../public/assets/icons/selection-mode/select-all.svg');
  background-size: cover;
  text-rendering: auto;
}

.fai-deselect-all {
  background-image: url('../../../public/assets/icons/selection-mode/deselect-all.svg');
  background-size: cover;
  text-rendering: auto;
}

.fai-place-into-seeding-table {
  background-image: url('../../../public/assets/icons/destination-selection/Repositioning_40x40px_Place-into-Seeding-Table.svg');
  background-size: cover;
  text-rendering: auto;
}

.fai-place-into-wall {
  background-image: url('../../../public/assets/icons/destination-selection/Repositioning_40x40px_Place-into-Wall.svg');
  background-size: cover;
  text-rendering: auto;
}

.fai-place-on-seeding-table {
  background-image: url('../../../public/assets/icons/destination-selection/Repositioning_40x40px_Place-on-Seeding-Table.svg');
  background-size: cover;
  text-rendering: auto;
}

.fai-put-to-nowhere {
  background-image: url('../../../public/assets/icons/destination-selection/Repositioning_40x40px_Put-to-Nowhere.svg');
  background-size: cover;
  text-rendering: auto;
}

.fair {
  height: 1.5rem;
  width: 1.5rem;
  filter: invert(100%);
}

.fais {  
  height: 1.5rem;
  width: 1.5rem;
}