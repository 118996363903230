.confirm-deletion-modal-container {
    width: 502px;
    height: 272px;
    background: #393939 0% 0% no-repeat padding-box;
    border: 1px solid #F5F5F5;
    border-radius: 6px;
    opacity: 1;
    position: relative;

    .confirm-deletion-modal-header-container {
        width: 500px;
        height: 71px;
        background: #535353 0% 0% no-repeat padding-box;
        border-bottom: 1px solid #F5F5F5;
        border-radius: 6px 6px 0px 0px;
        opacity: 1;
        position: absolute;
    }

    .confirm-deletion-modal-header {
        top: 23px;
        left: 25px;
        width: 330px;
        height: 25px;
        text-align: left;
        font-style: normal;
        font-variant: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        font-family: 'Quicksand';
        letter-spacing: 0px;
        color: #F5F5F5;
        opacity: 1;
        position: absolute;
    }

    .confirm-deletion-modal-body {
        top: 96px;
        left: 25px;
        width: 450px;
        height: 105px;
        text-align: left;
        font-style: normal;
        font-variant: normal;
        font-size: 16px;
        line-height: 20px;
        font-family: 'Quicksand';
        letter-spacing: 0px;
        color: #F5F5F5;
        opacity: 1;
        position: absolute;
    }

    .confirm-deletion-modal-cancel-button {
        top: 225px;
        left: 1px;
        width: 249px;
        height: 45px;
        background: #393939 0% 0% no-repeat padding-box;
        border-radius: 0px 0px 0px 6px;
        opacity: 1;
        text-align: left;
        font-style: normal;
        font-variant: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        font-family: 'Quicksand';
        letter-spacing: 0px;
        color: #F5F5F5;
        position: absolute;
    }

    .confirm-deletion-modal-confirm-button {
        top: 225px;
        left: 250px;
        width: 249px;
        height: 45px;
        background: #393939 0% 0% no-repeat padding-box;
        border-radius: 0px 0px 6px 0px;
        opacity: 1;
        text-align: left;
        font-style: normal;
        font-variant: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        font-family: 'Quicksand';
        letter-spacing: 0px;
        color: #F5F5F5;
        position: absolute;
    }
}