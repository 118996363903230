.bell-icon-background {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $secondary-color; // Grey background color
  
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  
    color: #fff; // Text color
    font-weight: bold;
    font-size: 16px;
}