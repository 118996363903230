#photographing-container {

  .upload-photo-btn {
    max-height: 150px;
    max-width: 300px;
    position: fixed;
    left: 60%;
    top: 50%;
    transform: translateY(-50%);
  }
  .submit {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // height: 100%;

    display: flex;
    flex-direction: row;
    min-width: 100%;

    .panel {
      height: 100%;
      padding-right: 5px;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        cursor: pointer;
      }
  
      &::-webkit-scrollbar-thumb {
        background: $tiny-scrollbar-color;
        cursor: pointer;
  
        &:hover {
          background: $tiny-scrollbar-hover-color;
        }
      }
    }

    .take-photo {
      height: 100%;
      width: 100%;
      align-self: center;
      justify-self: center;
      margin: 0 0 0 40px;
      position: relative;

      #poca {
        display: none;
      }

      #saved-image {
      }

      .camera-output {
        height: inherit;
        width: inherit;

        #saved-image {
          height: 100%;
        }
      }

      .file-select {
        height: 100% !important;
      }

      .btn {
        height: 100%;
        margin: auto;
        align-self: center;
      }
    }
  }
}
