#seed-management {
  align-self: flex-start;
}

.seed-management-container {
  padding: 1rem 1rem 6rem 1rem;
  height: 60vh !important;
  display: flex;
  justify-content: center;

  .seed-list-overview {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }

  .main {
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .seed-variant-type-card {
    color: $text-color;
    background: darken($primary-color, 5);
    height: auto;
    width: 100%;
    max-width: 450px;
    height: 317px;

    &.add-new-seed-form {
      max-width: 90%;
      margin: auto;
      grid-gap: 15px;
    }

    .add-seed-symbol {
      display: block;
      margin: auto;
      cursor: pointer;
    }

    .h4-input {
      font-size: 1.5rem;
      font-weight: 300;
    }

    .seed-property-input-field {
      border-radius: 5px;
      line-height: 1;
      padding: 6px;
      width: 100%;
      color: whitesmoke;
      background: lighten($primary-color, 5);
      outline: none;
      border: none;
      margin: 2px 0;
      border: 1px solid whitesmoke;

      &.in-add {
        width: unset;
      }

      &.invalid-field {
        border: 2px solid red;
      }

      &.header-input {
        // background: darken($primary-color, 2);
        background: $primary-color;
      }

      &::placeholder {
        color: lighten(darkgrey, 5);
        font-style: italic;
      }

      &[disabled] {
        border-color: transparent;
        background: none;
        opacity: 1;
        -webkit-appearance: none;
        -moz-appearance: none;
        // text-indent: 1px;
        // text-overflow: '';

        option {
          background: none;
          color: whitesmoke !important;
        }
      }
    }

    .lot-pos {
      position: absolute;
      left: 25px;
      top: 25px;
      width: 400px;
    }

    .manufacturer-pos {
      position: absolute;
      left: 25px;
      top: 88px;
      width: 400px;
    }

    .seed-count-pos {
      position: absolute;
      left: 25px;
      top: 151px;
      width: 175px;
    }

    .package-weight-pos {
      position: absolute;
      left: 250px;
      top: 151px;
      width: 175px;
    }

    .tgw-pos {
      position: absolute;
      left: 25px;
      top: 214px;
      width: 175px;
    }

    .card-body-input-row {
      height: 32px !important;
      input {
        height: 32px !important;
      }
      &.add-row {
        margin-right: 10px;

        p {
          border: 1px solid whitesmoke;
          border-radius: 5px;
          padding: 5px;
          font-size: 0.7rem;
          margin: auto;
        }
      }

      &.span-row {
        // grid-column-start: 1;
        // grid-column-end: 2;
        column-span: all;
      }

      .input-label {
        display: block;
        margin: 0;
        padding-left: 6px;
        font-weight: 300 !important;

        &.thin {
          font-weight: unset;
        }
      }

      .seed-property-input-field {
        font-style: italic;
      }
    }
    .seed-card-header {
      background-color: lighten($primary-color, 5);
      padding: 0.5rem 1.25rem !important;

      h4 {
        font-weight: 300;
      }
    }

    .seed-variant-type-card-body {
      height: max-content;
      padding: 0.5rem 1.25rem !important;

      .input-label {
        display: block;
        margin: 0;
        padding-left: 6px;
        font-weight: 600 !important;

        &.thin {
          font-weight: unset;
        }
      }

      &.add-new-seed-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
      }
    }

    
    .seed-card-footer {
      height: 40px !important;

      button {
        font-size: 21px !important;
      }

      .seed-card-text {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    .card-button {
      display: flex;
      font-size: 24px !important;
      .seed-card-btn {
        // margin: 5px 5px;
        // padding: 5px;
        padding-bottom: 7px;
        background: darken($primary-color, 5);
      }
    }

    .card-buttons {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      width: 50%;
    }
  }
}
