.process-selector {
  display: grid;
  place-items: center;
  align-self: baseline;
  // align-items: center;
  // justify-content: space-around;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.5rem;
  margin: auto;

  .btn {
    padding: 1.3rem 0;
    margin: 0.7rem;
    border-radius: 5px;
    height: 80px;
    font-size: 1.2rem;
    letter-spacing: 1px;
    font-weight: 400;
  }

  .process-button {
    @include tile;
    font-size: 1.5rem !important;
    height: 130px !important;
  }
}
