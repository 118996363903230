$border-color-small: lightgrey;
$loader-color-small: white;

$border-color-full-screen: rgba(200, 200, 200, 0.26);
$loader-color-full-screen: forestgreen;

.loading-container-inline {
  align-self: center;
  justify-self: center;
  margin: auto;
  background-color: $primary-color;
  z-index: 55555;

  .loader-text {
    z-index: 55556;
    border: 1px solid #464646;
    background-color: whitesmoke;
    border-radius: 5px;
    padding: 10px;
    text-align: center;

    .loader-text-animation {
      -webkit-animation: flashText 1.1s infinite linear;
      animation: flashText 1.1s infinite linear;
      font-weight: 900;
      margin: auto;
    }
  }

  .loader-text-inline {
    z-index: 55556;
    border: 1px solid #464646;
    background-color: transparent;
    border-radius: 5px;
    display: inline;

    .loader-text-animation {
      -webkit-animation: flashText 1.1s infinite linear;
      animation: flashText 1.1s infinite linear;
      font-weight: 900;
    }
  }

  .loader,
  .loader:after {
    border-radius: 50%;
  }
  .loader {
    width: 72px;
    height: 72px;
    margin: 0;
    font-size: 4px;
    text-indent: -9999rem;
    border-left: 1.1em solid $loader-color-small;
    border-top: 1.1em solid $border-color-small;
    border-right: 1.1em solid $border-color-small;
    border-bottom: 1.1em solid $border-color-small;
    -webkit-animation: inlineLoading 1.1s infinite linear;
    animation: inlineLoading 1.1s infinite linear;
    border-radius: 50%;

    &.fullScreen {
      position: absolute;
      top: 50%;
      left: 50%;
      border-left: 1.7em solid $loader-color-full-screen;
      border-top: 1.7em solid $border-color-full-screen;
      border-right: 1.7em solid $border-color-full-screen;
      border-bottom: 1.7em solid $border-color-full-screen;
      width: 10rem;
      height: 10rem;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-animation: fullScreenLoading 1.1s infinite linear;
      animation: fullScreenLoading 1.1s infinite linear;
    }
  }
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: whitesmoke;
  z-index: 55555;
  display: flex;
  justify-content: center;
  align-items: center;

  &.inline {
    position: relative;
    background: transparent;
    z-index: 55555;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 1em;
    padding-top: 2em;
    padding-bottom: 2em;
    // justify-self: center;
    // align-self: center;
  }

  &.fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: whitesmoke;
    z-index: 55555;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader-text {
    z-index: 55556;
    border: 1px solid #464646;
    background-color: whitesmoke;
    border-radius: 5px;
    padding: 10px;
    text-align: center;

    .loader-text-animation {
      -webkit-animation: flashText 1.1s infinite linear;
      animation: flashText 1.1s infinite linear;
      font-weight: 900;
      margin: auto;
    }
  }

  .loader-text-inline {
    z-index: 55556;
    border: 1px solid #464646;
    background-color: transparent;
    border-radius: 5px;
    display: inline;

    .loader-text-animation {
      -webkit-animation: flashText 1.1s infinite linear;
      animation: flashText 1.1s infinite linear;
      font-weight: 900;
    }
  }

  .loader,
  .loader:after {
    border-radius: 50%;
  }
  .loader {
    width: 24px;
    height: 24px;
    margin: 0;
    font-size: 4px;
    text-indent: -9999rem;
    border-left: 1.1em solid $loader-color-small;
    border-top: 1.1em solid $border-color-small;
    border-right: 1.1em solid $border-color-small;
    border-bottom: 1.1em solid $border-color-small;
    -webkit-animation: inlineLoading 1.1s infinite linear;
    animation: inlineLoading 1.1s infinite linear;
    border-radius: 50%;

    &.fullScreen {
      position: absolute;
      top: 50%;
      left: 50%;
      border-left: 1.7em solid $loader-color-full-screen;
      border-top: 1.7em solid $border-color-full-screen;
      border-right: 1.7em solid $border-color-full-screen;
      border-bottom: 1.7em solid $border-color-full-screen;
      width: 10rem;
      height: 10rem;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-animation: fullScreenLoading 1.1s infinite linear;
      animation: fullScreenLoading 1.1s infinite linear;
    }
  }
}
@-webkit-keyframes fullScreenLoading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes fullScreenLoading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes inlineLoading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes inlineLoading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes flashText {
  0% {
    opacity: 0;
    color: #464646;
  }

  50% {
    opacity: 1;
    color: lighten(#464646, 10);
  }

  100% {
    opacity: 0;
    color: #464646;
  }
}
@keyframes flashText {
  0% {
    opacity: 0;
    color: #464646;
  }

  50% {
    opacity: 1;
    color: lighten(#464646, 10);
  }

  100% {
    opacity: 0;
    color: #464646;
  }
}
