@import './transplanted-tray-overview';
@import './transplanted-panel-overview';

#transplanting-container {
  align-self: baseline;
  margin: auto;

  .transplanting-overview {
    // 
    
    display: flex;
    justify-content: center;
    grid-gap: 3rem;
  }
}
