a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    font-weight: 900;
  }
 
  .box-shadow {
    box-shadow: none;
  }

  .wide-navbar {
    width: 100% !important;
    min-width: 1024px !important;
  }
.icon-color {
  color: #808080;
  padding-right: 12px;
}

.login-initials{
  div{
      font-size: 12px !important;
  }
}