@import "./reporting/reporting";

#layout-container {
  // min-height: 100vh;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // background-image: url('../backgroundfarmer.jpg');

  min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // background-image: url('../backgroundfarmer.jpg');
    // background-color: #f5f5f5;
    // background-blend-mode: darken;
  

  &-body {
    // height: calc(100vh - 190px);
    // !!!OLD
    height: calc(100vh - 200px);
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1024px;

    // & > div {
    //   width: 100%;
    // }

    .process-container {
      align-self: baseline;
      height: fit-content;
      padding-left: 5rem;
      padding-right: 5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      width: 100%;

      // &#discarding-container,
      &#reporting-container, &#photographing-container {
        height: 100%;
      }

      &#reporting-container {
        align-self: unset;
      }
    }
    

    .process-container:not(#repositioning-container):not(#reporting-container):not(#seed-management) {
      margin: auto;
    }
  }
}

// css for Safari
#layout-container-safari {
  // min-height: 100vh;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // background-image: url('../backgroundfarmer.jpg');

  height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // background-image: url('../backgroundfarmer.jpg');
    // background-color: #f5f5f5;
    // background-blend-mode: darken;
  

  &-body {
    // height: calc(100vh - 190px);
    // !!!OLD
    zoom: 0.9;
    height: calc(100vh - 200px);
    overflow: auto;
    display: flex;
    align-items: baseline;
    justify-content: center;
    min-width: 1126.4px;
    // & > div {
    //   width: 100%;
    // }

    .process-container {
      align-self: baseline;
      height: fit-content;
      padding-left: 5rem;
      padding-right: 5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      width: 100%;

      // &#discarding-container,
      &#reporting-container, &#photographing-container {
        height: 100%;
      }

      &#reporting-container {
        align-self: unset;
      }
    }
    

    .process-container:not(#repositioning-container):not(#reporting-container):not(#seed-management) {
      margin: auto;
    }
  }
}

.italic {
  font-style: italic;
}

.tray-weighing-container {
  display: flex; 
  width: inherit;
}