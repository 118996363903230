.accordion {
  width: 100%;
  margin: 0.5rem 0;

  &-header {
    font-size: 1.3rem;
    font-weight: 600;
    cursor: pointer;
    background: darken(whitesmoke, 2);
    padding: 0.3rem 1rem;
    border-radius: 10px;
  }

  // Open-closed state is set in the component via maxHeight
  &-body {
    margin: 0.5rem 0;
    padding: 0 2rem;
    transition: 300ms all ease-out;
    overflow: hidden;

    .add-dialog-btn-container {
      @include flex-col-center;
      width: 100%;
    }
    .btn-add-minimal {
      position: relative;

        &::before,
        &::after {
            content: '';
            width: 400px;
            position: absolute;
            height: 2px;
            background: linear-gradient(to left, white, $text-color);
            top: 50%;
            left: 170px;
            border-radius: 10px;
        }
        
        &::after {
            left: unset;
            background: linear-gradient(to right, white, $text-color);
            right: 170px;
        }
    }
  }
}
