.discarding-confirmation-container {
  // display: grid;
  // grid-template-columns: 1fr;
  // border: 2px dotted black;
  // width: 500px;
  // height: 500px;
  // justify-content: center;
  // text-align: center;
  position: relative;
  align-content: center;
  //   background: $secondary-color;
  background: linear-gradient(
    149deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(212, 212, 217, 1) 51%,
    rgba(255, 255, 255, 1) 100%
  );
  color: $primary-color;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 grey;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 250ms all ease-out;
  margin: 1em;
  padding: 1em;

  .confirmation-header {
    //color: red;
    font-size: 0.8em;
  }

  .confirm-icon {
    font-size: 2em;
  }
}
