.harvesting-panel-container,
.weighing-panel-container {
  display: flex;
  flex-direction: column-reverse;
  margin: auto 0;

  .panel-selection-container-row {
    .plant-block {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      background: $primary-color;
      color: $text-color;
      margin: 5px 2px;
      font-size: 2rem;
      position: relative;

      &[data-small='true'] {
        align-items: baseline;
        font-size: 1.6rem;
        height: 55px;
      }

      .plant-name {
        @include plant-name;
      }

      .plant-icon {
        padding: 15px;
      }

      .plant-icon-text {
        @include plant-icon-text;
      }

      &[data-visible='false'] {
        display: none;
      }

      &[data-is-available='false'] {
        color: forestgreen;
      }

      &[data-is-available='true'],
      &[data-finished='true'] {
        background: $primary-color;
        *:not(.plant-icon-text) {
          display: none;
        }
      }

      &[data-selected='true'] {
        background: $secondary-color;
        // color: whitesmoke;
      }

      &[data-confirm='true'] {
        color: whitesmoke;
      }

      &[data-weighed='true'] {
        background: forestgreen;
        * {
          color: $text-color;
        }
      }

      .block-content-separator {
        margin: 0 6px;
      }
    }
  }
}

.discarding-panel-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 6rem;
  min-height: 1200px;

  .panel-selection-container-row {
    flex-direction: column;
    margin-right: 20px;

    .harvesting-channel-block {
      height: 100%;
    }

    .plant-block {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary-color;
      color: $text-color;
      margin: 5px 2px;
      font-size: 2rem;
      position: relative;
      height: 100%;
      border-bottom: 2px solid white;
      min-width: 60px;

      .plant-name {
        @include plant-name;
      }

      .plant-icon {
        color: inherit;
      }

      .plant-icon-text {
        @include plant-icon-text;
      }

      &[data-visible='false'] {
        display: none;
      }

      &[data-is-available='false'] {
        color: forestgreen;
      }

      &[data-is-available='true'],
      &[data-finished='true'] {
        background: $primary-color;
        *:not(.plant-icon-text) {
          display: none;
        }
      }

      &[data-selected='true'] {
        background: $secondary-color;
        // color: whitesmoke;
      }

      &[data-confirm='true'] {
        i {
          color: #c51414;
        }
      }

      &[data-weighed='true'] {
        background: forestgreen;
        * {
          color: $text-color;
        }
      }

      .block-content-separator {
        margin: 0 6px;
      }
    }
  }
}
