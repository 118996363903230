.sidebar-container {
  display: inline-block;
  position: fixed;
  align-items: center;
  background: #484848 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  color: whitesmoke;
  display: flex;
  cursor:pointer;

  height: 173px;
  justify-content: center;
  left: 0px;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: width 0.3s ease-out;
  width: 32px;
}

.sidebar-container .closed-sidebar-text {
  align-items: center;
  display: flex;
  font-size: 14px;
  text-align: center;
  transform: rotate(-90deg);
  transform-origin: center center;
  white-space: nowrap;
}

.sidebar-container-open {
  display: inline-block;
  position: fixed;
  align-items: center;
  background: #484848 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  color: whitesmoke;
  display: flex;
  cursor:pointer;

  flex-direction: column;
  height: 173px;
  justify-content: space-around;
  left: 0px;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: width 0.3s ease-out;
  width: 60px;

  .select-icon,
  .reset-icon {
    align-items: center;
    display: flex;
    height: 21px;
    justify-content: center;
    width: 25px;
    color: whitesmoke !important;
    cursor: pointer;
  }
}
