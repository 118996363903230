.seed-selection-container {
  margin: auto 0;
  padding-bottom: 6rem;

  .seed-selection-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .seed-group-body {
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
      // grid-gap: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 15px;
      gap: 10px;

      .seed-card {
        position: relative;
        z-index: 98;
        background-color: $primary-color;
        color: $text-color;
        text-align: center;
        min-width: 280px;
        max-width: 300px;

        &-header {
          background: lighten($primary-color, 7);
          text-align: center;
          border-bottom: 1px solid rgba(200, 200, 200, .6);

          .meta-title {
            font-style: italic;
          }
        }

        .card-body {
          padding: 15px 25px;

          .detail-row {
            display: flex;
            text-align: left;
            margin: 10px;

            .detail-label {
              font-weight: 600;
              margin-right: 20px;
              width: 50%;
            }
            
            .detail-value {
              width: 50%;

            }
          }

        }

        .card-footer {
          .footer-title {
            font-weight: 500;
            font-size: 1.1rem;
            font-style: italic;
          }
        }
      }

      .btn {
        font-size: 1.1rem;
        text-align: center;
      }
    }

    .btn {
      width: 280px;
      margin: 0.75rem;
    }
  }
}
