.overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(10, 10, 10, .8);
    cursor: pointer;
    z-index: 99;

    .close-btn {
        color: whitesmoke;
        font-size: 1.2rem;
        padding: 1rem;
        position: fixed;
        top: 25px;
        right: 45px;
        background: none;
        border: none;
        outline: none;
    }
}