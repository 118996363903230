.container-card {
  position: relative;

  color: $text-color;
  background: darken($primary-color, 5);
  height: auto;
  width: 100%;
  max-width: 450px;
  min-height: 150px;

  .container-card-header {
    background-color: #464646;
    height: 120px;
  }

  .container-name {
    position: absolute;
    top: 25px;
    left: 25px;
    height: 30px;
    width: 90%;
    font-size: 24px;
    font-weight: 700;
  }

  .container-location-icon {
    position: absolute;
    top: 74.5px;
    left: 25px;
    height: 24px;
    width: 18.05px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  .container-location-container {
    position: absolute;
    top: 67px;
    left: 53px;
    height: 38px;
    width: 90%;
    font-size: 16px;
    font-style: italic;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-items: flex-start;
  }

  .container-card-body {
    background-color: #393939;
    height: 162px;
    border-radius: 6px;
  }

  .container-description-label {
    position: absolute;
    top: 135px;
    left: 25px;
    font-weight: 700;
  }

  .container-description {
    position: absolute;
    top: 165px;
    left: 25px;
    height: 62px;
    width: 90%;
    font-style: italic;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
      background: $tiny-scrollbar-color;
      cursor: pointer;

      &:hover {
        background: $tiny-scrollbar-hover-color;
      }
    }
  }

  .container-card-btn {
    background: darken($primary-color, 5);
    width: 224px;
  }

  .container-card-btn:nth-child(1) {
    position: absolute;
    top: 242px;
    left: 0px;
    height: 40px;
  }

  .container-card-btn:nth-child(2) {
    position: absolute;
    top: 242px;
    left: 225px;
    height: 40px;
  }
}

.create-container-card {
  height: 284px;
  width: 450px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #464646;
  color: $text-color;
}
