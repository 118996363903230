.create-produce-type-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  left: 0px;
  min-width: 100%;

  .create-produce-type-card-container {
    height: 512px;
    width: 1024px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .create-produce-type-card {
    width: 958px;
    height: 512px;
    background: #393939 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    position: relative;
    display: inline-block;
    z-index: 1;

    .marketing-name-input-container {
      .marketing-name-input-label {
        position: absolute;
        top: 25px;
        left: 25px;
        height: 20px;
        width: 123px;
        text-align: left;
        color: whitesmoke;
      }

      .marketing-name-input {
        position: absolute;
        top: 50px;
        left: 25px;
        width: 429px;
        height: 32px;
        background-color: $text-input-background-color;
        border: 1px solid whitesmoke;
        border-radius: 5px;
        color: whitesmoke;
      }

      .marketing-name-error {
        position: absolute;
        top: 83px;
        left: 25px;
        color: lighten(red, 30);
        font-size: 0.8em;
      }
    }

    .classification-input-container {
      .classification-input-label {
        position: absolute;
        top: 102px;
        left: 25px;
        height: 20px;
        width: 97px;
        text-align: left;
        color: whitesmoke;
      }

      .classification-input {
        position: absolute;
        top: 127px;
        left: 25px;
        width: 429px;
        height: 32px;
        background-color: $text-input-background-color;
        border: 1px solid whitesmoke;
        border-radius: 5px;
        color: whitesmoke;
      }

      .classification-error {
        position: absolute;
        top: 162px;
        left: 25px;
        color: lighten(red, 30);
        font-size: 0.8em;
      }
    }

    .produce-number-and-gtin-input-container {
      .produce-number-input-label {
        position: absolute;
        top: 179px;
        left: 25px;
        height: 20px;
        width: 125px;
        text-align: left;
        color: whitesmoke;
      }

      .produce-number-input {
        position: absolute;
        top: 204px;
        left: 25px;
        width: 200px;
        height: 32px;
        background-color: $text-input-background-color;
        border: 1px solid whitesmoke;
        border-radius: 5px;
        color: whitesmoke;
      }

      .produce-number-error {
        position: absolute;
        top: 238px;
        left: 25px;
        color: lighten(red, 30);
        font-size: 0.8em;
      }

      .gtin-input-label {
        position: absolute;
        top: 179px;
        left: 254px;
        height: 20px;
        width: 36px;
        text-align: left;
        color: whitesmoke;
      }

      .gtin-input {
        position: absolute;
        top: 204px;
        left: 254px;
        width: 200px;
        height: 32px;
        background-color: $text-input-background-color;
        border: 1px solid whitesmoke;
        border-radius: 5px;
        color: whitesmoke;
      }

      .gtin-error {
        position: absolute;
        top: 238px;
        left: 254px;
        color: lighten(red, 30);
        font-size: 0.8em;
      }
    }

    .template-container {
      .template-label {
        position: absolute;
        top: 256px;
        left: 25px;
        height: 20px;
        width: 166px;
        text-align: left;
        color: whitesmoke;
      }

      .template-select {
        position: absolute !important;
        top: 281px !important;
        left: 25px !important;
        width: 429px !important;
        height: 32px !important;
        background-color: $text-input-background-color !important;
        border: 1px solid whitesmoke !important;
        border-radius: 5px !important;
        color: whitesmoke;
      }
    }

    .seed-selection-container {
      .seed-selection-label {
        position: absolute;
        top: 333px;
        left: 25px;
        height: 20px;
        width: 80px;
        text-align: left;
        color: whitesmoke;
      }

      .seed-selection-display {
        position: absolute;
        top: 358px;
        left: 25px;
        width: 374px;
        height: 32px;
        background-color: $text-input-background-color;
        border: 1px solid whitesmoke;
        border-radius: 5px;
        color: whitesmoke;
      }

      .seed-selection-button {
        position: absolute;
        top: 358px;
        left: 412px;
        width: 40px;
        height: 32px;
        background-color: $text-input-background-color;
        border: 1px solid whitesmoke;
        border-radius: 5px;
        color: whitesmoke;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .produce-image-upload-container {
      .produce-image-upload-label {
        position: absolute;
        top: 25px;
        left: 502px;
        width: 112px;
        height: 20px;
        color: whitesmoke;
      }

      .produce-image-upload-display {
        position: absolute;
        top: 50px;
        left: 502px;
        width: 429px;
        height: 186px;
        background-color: $text-input-background-color;
        border: 1px solid whitesmoke;
        border-radius: 5px;
        color: whitesmoke;
        display: flex;
        justify-content: center;
        align-items: center;

        .take-button {
          position: absolute !important;
          width: 86px;
          height: 86px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $text-input-background-color;
          font-size: 76px;

          i {
            font-size: inherit;
          }
        }
      }

      .saved-image {
        position: absolute;
        top: 50px;
        left: 502px;
        width: 429px;
        height: 186px;
        background-color: $text-input-background-color;
        border: 1px solid whitesmoke;
        border-radius: 5px;
        color: whitesmoke;
        display: flex;
        justify-content: center;
        align-items: center;

        .retake-button {
          position: absolute;
          top: 5px;
          left: 5px;
          border-radius: 50%;
          background-color: $primary-color;
          border: 1px solid whitesmoke;
          color: whitesmoke;
          opacity: 0.7;
        }
      }
    }

    .appetizer-input-container {
      .appetizer-input-label {
        position: absolute;
        top: 256px;
        left: 502px;
        height: 20px;
        width: 105px;
        text-align: left;
        color: whitesmoke;
      }

      .appetizer-input {
        position: absolute;
        top: 281px;
        left: 502px;
        width: 429px;
        height: 32px;
        background-color: $text-input-background-color;
        border: 1px solid whitesmoke;
        border-radius: 5px;
        color: whitesmoke;
      }
    }

    .description-input-container {
      .description-input-label {
        position: absolute;
        top: 333px;
        left: 502px;
        height: 20px;
        width: 148px;
        text-align: left;
        color: whitesmoke;
      }

      .description-input {
        position: absolute;
        top: 358px;
        left: 502px;
        width: 429px;
        height: 100px;
        background-color: $text-input-background-color;
        border: 1px solid whitesmoke;
        border-radius: 5px;
        color: whitesmoke;
      }
    }
  }
}
