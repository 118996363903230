.tenant-user-management-card {
  width: 450px;
  min-height: 292px;
  background: #393939 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  position: relative;

  .tenant-user-management-card-header {
    width: 450px;
    height: 95px;
    background: #535353 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    opacity: 1;

    .tenant-user-management-card-name {
      left: 25px;
      top: 25px;
      text-align: left;
      font-style: normal;
      font-variant: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      font-family: 'Quicksand';
      letter-spacing: 0.5px;
      color: #f5f5f5;
      opacity: 1;
      position: absolute;
    }
  }

  .tenant-user-management-card-label {
    left: 25px;
    text-align: left;
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Quicksand';
    letter-spacing: 0px;
    color: #f5f5f5;
    opacity: 1;
    position: absolute;
  }
  .tenant-user-management-card-error-message {
    position: absolute;
    top: 173px;
    left: 25px;
    color: $form-error-message-color;
    font-size: 0.8em;
  }

  .tenant-user-management-card-email.tenant-user-management-card-label {
    top: 116px;
  }

  .tenant-user-management-card-roles.tenant-user-management-card-label {
    top: 195px;
  }

  .tenant-user-management-card-email.tenant-user-management-card-value {
    left: 25px;
    top: 146px;
    text-align: left;
    font-style: italic;
    font-variant: normal;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Quicksand';
    letter-spacing: 0px;
    color: #f5f5f5;
    opacity: 1;
    position: absolute;
  }

  .tenant-user-management-card-email-input {
    left: 25px;
    top: 141px;
    width: 400px;
    height: 32px;
    background: #535353 0% 0% no-repeat padding-box;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    text-align: left;
    font-style: italic;
    font-variant: normal;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Quicksand';
    letter-spacing: 0px;
    color: #f5f5f5;
    opacity: 1;
    position: absolute;
  }

  .tenant-user-management-card-checkbox-value-disabled {
    appearance: none;
    top: 220px;
    width: 16px;
    height: 16px;
    background: #535353 0% 0% no-repeat padding-box;
    border: 1px solid #b3b3b380;
    opacity: 1;
    position: absolute;
  }

  .tenant-user-management-card-checkbox-value-disabled:checked::before {
    content: '\2713';
    color: #b3b3b3;
    position: absolute;
    top: -2px;
    left: 2px;
    font-size: 14px;
  }

  .tenant-user-management-card-checkbox-value-disabled:checked {
    appearance: none;
    top: 220px;
    width: 16px;
    height: 16px;
    background: #535353 0% 0% no-repeat padding-box;
    border: 1px solid #b3b3b380;
    opacity: 1;
    position: absolute;
  }

  .tenant-user-management-card-checkbox-value-enabled {
    appearance: none;
    top: 220px;
    width: 16px;
    height: 16px;
    background: #ffffff00 0% 0% no-repeat padding-box;
    border: 1px solid #f5f5f5;
    opacity: 1;
    position: absolute;
  }

  .tenant-user-management-card-checkbox-value-enabled:checked::before {
    content: '\2713';
    color: #ffffff;
    position: absolute;
    top: -2px;
    left: 2px;
    font-size: 14px;
  }

  .tenant-user-management-card-checkbox-value-enabled:checked {
    appearance: none;
    top: 220px;
    width: 16px;
    height: 16px;
    background: #ffffff00 0% 0% no-repeat padding-box;
    border: 1px solid #f5f5f5;
    opacity: 1;
    position: absolute;
  }

  .tenant-user-management-card-operator.tenant-user-management-card-checkbox-value-disabled,
  .tenant-user-management-card-operator.tenant-user-management-card-checkbox-value-enabled {
    left: 28px;
  }

  .tenant-user-management-card-marketing.tenant-user-management-card-checkbox-value-disabled,
  .tenant-user-management-card-marketing.tenant-user-management-card-checkbox-value-enabled {
    left: 145px;
  }

  .tenant-user-management-card-tenant-admin.tenant-user-management-card-checkbox-value-disabled,
  .tenant-user-management-card-tenant-admin.tenant-user-management-card-checkbox-value-enabled {
    left: 272px;
  }

  .tenant-user-management-card-checkbox-label {
    top: 219px;
    text-align: left;
    font-style: italic;
    font-variant: normal;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Quicksand';
    letter-spacing: 0px;
    color: #f5f5f5;
    opacity: 1;
    position: absolute;
  }

  .tenant-user-management-card-operator.tenant-user-management-card-checkbox-label {
    left: 46px;
  }

  .tenant-user-management-card-marketing.tenant-user-management-card-checkbox-label {
    left: 166px;
  }

  .tenant-user-management-card-tenant-admin.tenant-user-management-card-checkbox-label {
    left: 293px;
  }

  .tenant-user-management-card-button {
    top: 252px;
    width: 225px;
    height: 40px;
    background: #393939 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    position: absolute;
    cursor: pointer;
  }

  .tenant-user-management-card-right.tenant-user-management-card-button {
    left: 225px;
  }

  /// Hier müssen noch Button Komponenten statt divs verwendet werden (In der Edit-Komponente), dann können diese drei Klassen raus.
  /// Button-Funktionalität haben wir in der entsprechenden Komponente isoliert.
  .tenant-user-management-card-button-label {
    top: 5px;
    font-size: 1.3rem;
    font-family: 'Quicksand';
    font-weight: 600;
    letter-spacing: 0px;
    color: #f5f5f5;
    opacity: 1;
    position: absolute;
  }

  .tenant-user-management-card-left.tenant-user-management-card-button-label {
    left: 76px;
  }

  .tenant-user-management-card-right.tenant-user-management-card-button-label {
    left: 91px;
  }
}
