.seed-details {
    display: grid;
    // grid-template-columns: 1fr 2fr;
    grid-template-areas: "head head" "varieties editor" "varieties editor" "varieties editor";
    grid-template-columns: 1fr 2fr;
    height: 100%;

    .seedVariantTypes-header {
        column-span: all;
        grid-area: head;
        margin: auto;
        background: $primary-color;
        width: 100%;
        text-align: center;

        .number {
            color: whitesmoke;
            font-weight: 700;
        }
    }

    .seedVariantTypes-view {
        height: 80%;
        border: 1px solid black;
        margin: 20px 20px;
        background-color: $primary-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        overflow: auto;
        grid-area: varieties;

        .highlight {
            border: 2px solid whitesmoke;
        }

        .seed-card {
            background-color: $secondary-color;
            color: $text-color;
            width: 80%;
            text-align: center;
            // margin: 10px 10px;
            padding: 5px;
            // display: grid;
            // grid-template-columns: 1fr 1fr;
            // margin: auto;
            margin-bottom: 5px;

            .seed-card-info {
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin: auto;
                margin-bottom: 5px;
            }

            .delete-button {
                //
                font-weight: 700;
                cursor: pointer;
                background-color: $primary-color;
                border-radius: 10px;
                padding: 10px;

                i {
                    font-size: 1rem;
                }
            }

            .detail-label {
                font-size: 0.7rem;
            }

            .detail-text {
                font-size: 0.7rem;
                font-weight: 700;
            }
        }
    }

    .edit-seed-container {
        height: 80%;
        border: 1px solid black;
        margin: 20px 20px;
        grid-area: editor;
        background-color: lighten(#393939,50);

        .seed-form {
            height: 100% !important;
        }
    }
}
