#container-table {
  background-color: whitesmoke;
  border: none;
  margin: 20px auto;
  padding: 5px;
  display: table;
  overflow: auto;
  width: 90%;
  font-size: 1.2em;
}

textarea {
  resize: none;
}

tr:last-child {
  border: none;
}

td {
  // border-bottom: 1px solid #464646;
  border-top: none;
  margin-top: 20px;
  padding: 40px;
}

td.editable {
  cursor: pointer;
}

.container-table-row {
  width: 100%;
  border-bottom: 1px solid black;
}

.popup-form {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 20px;
}

.container-card {
  color: whitesmoke;
  background: #393939;
  height: 150px;
  width: 100%;
  max-width: 450px;
}

.container-card-devices {
  display: grid;
  grid-template-columns: 6fr 1fr 1fr;

  i {
    cursor: pointer;
  }
}

.model-config {
  min-height: 336px !important;
}

#tag-input {}

#cameras {
  // min-height: 200px;
  // display: flex;
  // flex-flow: wrap;
}

#tag-input-board {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  gap: 5px;
  margin-top: 5px;
  margin-bottom: 10px;

  overflow: auto;
}

#tag-input-field {
  input {
    width: 100%;
  }
}

.sweden-tag {
  background-color: whitesmoke;
  border-radius: 10px;
  color: #393939;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;
  padding-bottom: 0px;

  font-weight: 900 !important;

  display: flex;
  justify-content: center;
  align-items: center;
}

.sweden-tag-centered {
  text-align: center;
}

.sweden-tag-delete {
  background-color: whitesmoke;
  border-radius: 10%;
  color: #393939;
  margin-left: 5px;
  font-weight: 900 !important;
  font-size: 1em;

  display: flex;
  justify-content: center;
  align-items: center;
  /* align-self: center; */
  min-width: 40px;
  cursor: pointer;
}

.tag-error {
  color: whitesmoke;
}

.camera-error {
  font-size: 16px;
  font-weight: inherit;
}

.loading-padding {
  padding-top: 3.5em;
  padding-bottom: 3.5em;
}

.weighing-camera-settings-card-header {
  color: $text-color;
  background-color: lighten($primary-color, 5);
}

.weighing-camera-settings-card-body,
.weighing-camera-settings-card-footer {
  color: $text-color;
  background: darken($primary-color, 5);
  // min-height: 13em;
}

.weighing-camera-settings-card-buttons {
  // margin-top: 20px;
  margin: 0px;
  padding: 0 !important;
  border: none !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.weighing-camera-settings-card-body-enabled {
  // border-top: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
  padding-top: 5px !important;
  margin-bottom: 5px !important;
}

.weighing-camera-settings-card-body-enabled-text {
  text-align: center;
  align-self: center;
  display: flex;

  label {
    margin: 0px;
  }
}

.weighing-camera-settings-card-body-enabled-switch {

  label {
    margin: 0px;
  }
}

label {
  margin: 0px !important;
}


.weighing-camera-settings-card-btn {
  background: darken($primary-color, 5);
}

.weighing-camera-settings-card-btn-submit {
  background: darken($primary-color, 5);
  margin: 5px 5px;
  padding: 5px;
  width: 270%;
}

.modal-content {
  background: $primary-color;
}

.weighing-camera-settings-input {
  border-radius: 5px;
  line-height: 1;
  padding: 6px;
  width: 100%;
  color: whitesmoke;
  background: lighten($primary-color, 5);
  outline: none;
  border: none;
  margin: 2px 0;
  border: 1px solid whitesmoke;
}

.weighing-camera-settings-input[disabled] {
  border-radius: 5px;
  line-height: 1;
  padding: 6px;
  width: 100%;
  color: whitesmoke;
  background: darken($primary-color, 5);
  outline: none;
  border: none;
  margin: 2px 0;
  border: 1px solid darken($primary-color, 5);
}

// .card {
//   position: relative !important;
//   display: flex !important;
//   flex-direction: column !important;
//   min-width: 0 !important;
//   word-wrap: break-word !important;
//   background-color: #fff !important;
//   background-clip: border-box !important;
//   border: 1px solid rgba(0,0,0,.125) !important;
//   border-radius: 0.25rem !important;
// }

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  transform: scale(0.8);
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: lighten($primary-color, 1);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: lighten($primary-color, 25);
  ;
}

input:focus+.slider {
  box-shadow: 0 0 1px lighten($primary-color, 25);
  ;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

hr {
  border-color: whitesmoke;
  display: inline-block;
  width: 215px;
  position: relative;
  top: .75em;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #b15555;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  -ms-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
}

.pin:after {
  content: '';
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #302558;
  position: absolute;
  border-radius: 50%;
}

.pulse {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  -webkit-transform: rotateX(55deg);
  -moz-transform: rotateX(55deg);
  -o-transform: rotateX(55deg);
  -ms-transform: rotateX(55deg);
  transform: rotateX(55deg);
  z-index: -2;
}

.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  -webkit-animation: pulsate 1s ease-out;
  -moz-animation: pulsate 1s ease-out;
  -o-animation: pulsate 1s ease-out;
  -ms-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-box-shadow: 0 0 1px 2px #b15555;
  box-shadow: 0 0 1px 2px #b15555;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.location-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.location-search-close {
  padding-top: 2px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  top: 5px;
  color: whitesmoke;
  background: lighten($primary-color, 5);
}

.location-search-reset {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2px;
  padding-bottom: 14px;
  position: absolute;
  right: 5px;
  top: 5px;
  color: whitesmoke;
  background: lighten($primary-color, 5);
}

.location-search-icon {
  transform: translateY(35%);
}

.location-map-container {
  height: 70vh;
}

.pac-container {
  z-index: 2000 !important;
  color: whitesmoke;
  background: lighten($primary-color, 5);
  border: none;
}

.pac-item:hover {
  color: black !important;
  background: white !important;
}

.pac-item {
  color: whitesmoke;
  background: lighten($primary-color, 5);
  line-height: 3em;
  font-size: 15px;
}

.pac-item-query {
  color: inherit;
  background: inherit;
  font-size: 17px;
}

.pac-icon {
  vertical-align: middle;
  margin-left: 15px;
  margin-right: 23px;
  margin-top: 0 !important;
}

.container-card-location {
  display: grid;
  grid-template-columns: 6fr 1fr 1fr;
  font-style: italic;
  margin: 0;
  padding: 0;
}

.location-property-text {
  margin-bottom: 0;
}

.location-search-input {
  box-sizing: border-box;
  line-height: 1;
  padding: 6px;
  width: 100%;
  color: whitesmoke;
  background: lighten($primary-color, 5);
  border: none;
  margin: 2px 0;
  line-height: 2em;
  text-indent: 50px;
}

.container-header-titel {
  padding-left: 0 !important;
}
