.container-management-detail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: baseline;
    left: 0px;
    min-width: 100%;
    zoom: reset;
    height: 887px;


    .container-management-detail-card-container {
        height: 887px;
        width: 958px;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .container-management-detail-card {
        position: relative;
        display: inline-block;
        width: 958px;
        height: 887px;
        background: #393939 0% 0% no-repeat padding-box;
        border-radius: 6px;
        opacity: 1;

        .container-management-details-title {
            position: absolute;
            height: 30px;
            text-align: left;
            font-style: normal;
            font-variant: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #F5F5F5;
            opacity: 1;
        }

        .container-management-details-icon {
            position: absolute;
            display: flex;
            align-items: center;
            text-align: center;
            color: #F5F5F5;
            opacity: 1;
        }

        .container-management-details-label {
            position: absolute;
            height: 20px;
            text-align: left;
            font-style: normal;
            font-variant: normal;
            font-size: 16px;
            line-height: 20px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #F5F5F5;
            opacity: 1;
        }

        .container-management-details-value {
            position: absolute;
            height: 18px;
            text-align: left;
            font-style: italic;
            font-variant: normal;
            font-size: 16px;
            line-height: 19px;
            font-family: 'Quicksand';
            letter-spacing: 0px;
            color: #F5F5F5;
            opacity: 1;
        }

        .container-management-details-general.container-management-details-title {
            top: 25px;
            left: 25px;
        }

        .container-management-details-name.container-management-details-icon {
            top: 67px;
            left: 30px;
            width: 23px;
            height: 16px;
        }

        .container-management-details-name.container-management-details-label {
            top: 65px;
            left: 55px;
        }

        .container-management-details-name.container-management-details-value {
            top: 97px;
            left: 25px;
        }

        .container-management-details-location.container-management-details-icon {
            top: 144px;
            left: 30px;
            width: 12px;
            height: 16px;
        }

        .container-management-details-location.container-management-details-label {
            top: 142px;
            left: 55px;
        }

        .container-management-details-location.container-management-details-value {
            top: 173px;
            left: 25px;
        }

        .container-management-details-description.container-management-details-icon {
            top: 66.9px;
            left: 508.71px;
            width: 18px;
            height: 16px;
        }

        .container-management-details-description.container-management-details-label {
            top: 65px;
            left: 541px;
        }

        .container-management-details-description.container-management-details-value {
            top: 95px;
            left: 503px;
            height: 150px;
            width: 419px;
            overflow:auto;

            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
                cursor: pointer;
              }
          
              &::-webkit-scrollbar-thumb {
                background: $tiny-scrollbar-color;
                cursor: pointer;
          
                &:hover {
                  background: $tiny-scrollbar-hover-color;
                }
              }
        }

        .container-management-details-devices.container-management-details-title {
            top: 259px;
            left: 25px;
        }

        .container-management-details-scale.container-management-details-icon {
            top: 301px;
            left: 30px;
            width: 20px;
            height: 16px;
        }

        .container-management-details-scale.container-management-details-label {
            top: 299px;
            left: 55px;
        }

        .container-management-details-scale.container-management-details-value {
            top: 331px;
            left: 25px;
            letter-spacing: 1.4px;
        }

        .container-management-details-label-printer.container-management-details-icon {
            top: 382px;
            left: 30px;
            width: 16px;
            height: 16px;
        }

        .container-management-details-label-printer.container-management-details-label {
            top: 376px;
            left: 55px;
        }

        .container-management-details-label-printer.container-management-details-value {
            top: 408px;
            left: 25px;
            letter-spacing: 1.4px;
        }

        .container-management-details-weighing-camera.container-management-details-icon {
            top: 455px;
            left: 30px;
            width: 18px;
            height: 16px;
        }

        .container-management-details-weighing-camera.container-management-details-label {
            top: 453px;
            left: 55px;
        }

        .container-management-details-weighing-camera.container-management-details-value {
            top: 485px;
            left: 25px;
            letter-spacing: 1.4px;
        }

        .container-management-details-arlo.container-management-details-icon {
            top: 301px;
            left: 507px;
            width: 24px;
            height: 16px;

            .fas.fa-video {
                font-size: 22px;
            }
        }

        .container-management-details-arlo.container-management-details-label {
            top: 299px;
            left: 541px;
        }

        .container-management-details-arlo.container-management-details-value {
            top: 331px;
            left: 507px;
        }

        .container-management-details-miscellaneous.container-management-details-title {
            top: 543px;
            left: 25px;
        }

        .container-management-details-farmhand.container-management-details-icon {
            top: 585px;
            left: 30px;
            width: 20px;
            height: 16px;
        }

        .container-management-details-farmhand.container-management-details-label {
            top: 583px;
            left: 55px;
        }

        .container-management-details-farmhand.container-management-details-value {
            top: 615px;
            left: 25px;
        }

        .container-management-details-harvesting-procedures.container-management-details-icon {
            top: 662px;
            left: 30px;
            width: 18px;
            height: 16px;
        }

        .container-management-details-harvesting-procedures.container-management-details-label {
            top: 660px;
            left: 55px;
        }

        .container-management-details-harvesting-procedures.container-management-details-value {
            top: 692px;
            left: 30px; 
        }

        .container-management-details-storage-advice-cut.container-management-details-icon {
            top: 773px;
            left: 507px;
            width: 18px;
            height: 16px;
        }

        .container-management-details-storage-advice-cut.container-management-details-icon.disabled {           
            color: $text-color-forbidden;
        }

        .container-management-details-storage-advice-cut.container-management-details-label {
            top: 771px;
            left: 541px;
        }

        .container-management-details-storage-advice-cut.container-management-details-label.disabled {
            color: $text-color-forbidden;
        }

        .container-management-details-storage-advice-cut.container-management-details-value {
            top: 803px;
            left: 507px;
            overflow-y: auto;
            width: 419px;
            height: 56px;           

            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
                cursor: pointer;
              }
          
              &::-webkit-scrollbar-thumb {
                background: $tiny-scrollbar-color;
                cursor: pointer;
          
                &:hover {
                  background: $tiny-scrollbar-hover-color;
                }
              }
        }

        .container-management-details-storage-advice-cut.container-management-details-value.disabled {
            color: $text-color-forbidden;
        }

        .container-management-details-storage-advice-root.container-management-details-icon {
            top: 662px;
            left: 507px;
            width: 20px;
            height: 16px;
        }

        .container-management-details-storage-advice-root.container-management-details-icon.disabled {
            color: $text-color-forbidden;
        }

        .container-management-details-storage-advice-root.container-management-details-label {
            top: 660px;
            left: 541px;
        }

        .container-management-details-storage-advice-root.container-management-details-label.disabled {
            color: $text-color-forbidden;
        }

        .container-management-details-storage-advice-root.container-management-details-value {
            top: 692px;
            left: 511px;
            height: 56px;
            overflow-y: auto;
            width: 419px;
           

            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
                cursor: pointer;
              }
          
              &::-webkit-scrollbar-thumb {
                background: $tiny-scrollbar-color;
                cursor: pointer;
          
                &:hover {
                  background: $tiny-scrollbar-hover-color;
                }
              }
        }

        .container-management-details-storage-advice-root.container-management-details-value.disabled {
            color: $text-color-forbidden;
        }

        .container-management-details-list {
            display: flex;
            flex-direction: column;
            gap: 20px;
            max-height: calc(5 * (100px + 20px));
            overflow-y: auto;
            width: 300px;
            height: 250px;

            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
                cursor: pointer;
              }
          
              &::-webkit-scrollbar-thumb {
                background: $tiny-scrollbar-color;
                cursor: pointer;
          
                &:hover {
                  background: $tiny-scrollbar-hover-color;
                }
              }
        }

        .container-management-details-item {
            display: flex;
        }

        .container-management-details-weighing-camera-active.container-management-details-icon {
            width: 16px;
            height: 16px;
            top: 455px;
            left: 237px;
        }

        .container-management-details-scale-copy.container-management-details-icon {
            top: 332px;
            left: 381px;
            width: 16px;
            height: 16px;
        }

        .container-management-details-label-printer-copy.container-management-details-icon {
            top: 409px;
            left: 381px;
            width: 16px;
            height: 16px;
        }

        .container-management-details-weighing-camera-copy.container-management-details-icon {
            top: 486px;
            left: 381px;
            width: 16px;
            height: 16px;
        }
    }
}