.panel {
  display: flex;
  justify-content: center;
  // overflow-x: auto;
  margin: auto 0;
  height: 90%;
  //min-height: 900px;
  gap: 5px;
  min-width: fit-content;

  &[data-alignment='horizontal'] {
    flex-direction: column-reverse;
    height: auto;
    width: 100%;

    .channel {
      height: unset;
      width: 100%;
      display: flex;
            

      .channel-block {
        height: 60px;
      }
    }
  }

  .channel {
    height: 150%;
    min-width: 60px;
    
    &:not(.channel:last-of-type) {
      margin-right: 20px !important;
    }

    .channel-block {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: $primary-color;
      color: $text-color;
      // margin-bottom: 1px;
      border-bottom: 1px solid white;
      font-size: 0.8rem;
      position: relative;
      border-right: 1px solid white;

      &[data-current-photo='true'] {        

        .plant-icon {
          font-size: 1.2rem;
        }
        .plant-icon-small {
          font-size:0.8rem;
          color: lighten(forestgreen,40);
        }
      }

      &:last-child {
        border: none;
      }

      &[data-selected='true'] {
        background: lighten($primary-color, 20);

        &[data-being-deselected='true'] {
          position: relative;
          background: lighten($primary-color,10);
          // &::after {
          //   content: '';
          //   width: 12px;
          //   height: 12px;
          //   border-radius: 50%;
          //   background: rgba(199, 20, 20, 0.842);
          //   position: absolute;
          //   top: 5px;
          //   right: 5px;
          // }
        }
      }      

      &[data-occupied='true'] {
        .plant-icon {
          color: forestgreen;
        }
      }

      &[data-disabled='true'] {
        &[data-end-of-life='true'] {
          .plant-icon {
            color: whitesmoke !important;          
          }
        }        
      }

      .plant-name {
        position: absolute;
        top: 0px;
        left: 2px;
      }

      .plant-icon {
        padding: 10px;
        font-size: 1.6rem;
      }

      &.discarding-confirm {
        &[data-selected='true'] {
          .plant-icon {
            color: red;
          }
        }
      }

      &.photographing-confirm {
        &[data-selected='true'] {
          background-color: forestgreen !important;
          .plant-icon {
            color: whitesmoke;
          }          
        }        

        &[data-in-selection='true'] {
          background-color: lighten($primary-color, 10);
          .plant-icon {
            color: whitesmoke;
          }
        }

        &[data-in-selection='false'] {
          background-color: $primary-color;
          .plant-icon {
            color: whitesmoke;
          }
        }
      }

      &.weighing {
        .plant-icon {
          color: whitesmoke;
        }

        &[data-occupied='false'] {
          .plant-icon {
            display: none;
          }
        }

        &[data-to-be-weighed='true'] {
          .plant-icon {
            color: forestgreen !important;
          }
        }

        &[data-selected='true'] {
          background: forestgreen !important;

          .plant-icon {
            color: whitesmoke !important;
          }

          &[data-being-deselected='true'] {
            position: relative;
            background: lighten($primary-color,10);
            // &::after {
            //   content: '';
            //   width: 12px;
            //   height: 12px;
            //   border-radius: 50%;
            //   background: rgba(199, 20, 20, 0.842);
            //   position: absolute;
            //   top: 5px;
            //   right: 5px;
            // }
          }
        }

        &[data-in-selection='true'] {
          background: lighten($primary-color,20);
        }

        &[data-finished='true'] {
          .plant-icon {
            color: lightgreen;
          }
        }
      }

      .block-code {
        @include plant-icon-text;
        font-size:0.6rem;
      }
      .plant-icon-text {
        @include plant-icon-text;
      }
    }
  }
}
