.modal-backdrop {
  width: 100%;
}

.pl-modal {
  display: relative;
  width: 500px;
  height: 295px !important;
  top: 0px;
  left: 0px;
  background-color: #393939 !important;
  border-radius: 6px !important;


  .pl-modal-header-container {
    position: absolute;
    left: 0px;
    height: 60px;
    width: 100%;
    border-bottom: 1px solid whitesmoke;
    display: flex;
    justify-content: flex-start;
    padding-left: 25px;
    align-items: center;

    .pl-modal-header {
      left: 25px;
      font-size: 20px;
    }
  }

  .container-label {
    position: absolute;
    top: 90px;
    left: 25px;
  }

  .container-select {
    position: absolute;
    top: 122px;
    left: 25px;
    width: 429px;
    height: 32px;
  }

  .procedure-label {
    position: absolute;
    top: 167px;
    left: 25px;
  }

  .procedure-select {
    position: absolute;
    top: 199px;
    left: 25px;
    width: 429px;
    height: 32px;
  }

  .pl-buttons {
    position: absolute;
    left: 0px;
    top: 249px;
    display: flex;
    background-color: #393939;
    height: 45px;
    border-radius: 5px;

    button {
      min-width: 249px;
      width: 240px;
      background-color: inherit;
    }
  }
}

.print-label-modal {  
  background-color: #393939;
  border-radius: 6px !important;

  .modal-content {    
    background-color: #393939;
    width: 500px;
    height: 295px !important;
    border-radius: 6px !important;
  }

  .print-label-modal-header {
    background-color: #393939;
    color: whitesmoke;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .print-label-modal-header-text {
      font-weight: 700;
      font-size: 20px;
    }
  }

  .print-label-modal-body {
    background-color: #393939;
    color: whitesmoke;
    padding: 5px;

    .container-select {
      position: absolute;
      top: 121px;
      left: 25px;
    }

    .custom-label {
      margin-bottom: 5px;
    }

    .container-label {
      position: absolute;
      top: 96px;
      left: 25px;
    }    
  }

  .print-label-modal-footer {
    background-color: #393939;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    height: fit-content;
    padding: 5px !important;

    button {
      background-color: inherit;
      height: 45px;
    }
  }

  select {
    background-color: $text-input-background-color !important;
    border-radius: 5px;
    border: 1px solid $text-input-border-color;
    color: $text-input-text-color;
    font-size: 0.8em;
    width: 100%;
    min-height: 2em;
    margin-bottom: 10px;
    font-style: italic;
  }
}
