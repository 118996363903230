@mixin tile {
    background: $primary-color;
    color: $text-color;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 grey;
    height: 150px;
    width: 260px;
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 250ms all ease-out;
    margin: 1rem;
    
    &[data-selected="true"]{
        margin-bottom: 1rem;
        background: lighten($primary-color, 5)
    }
}

@mixin plant-name {
    font-size: .7rem;
    position: absolute;
    top: 1px;
    left: 5px;
    color: whitesmoke;
}

@mixin plant-icon-text {
    font-size: .8rem;
    position: absolute;
    bottom: 0;
    right: 5px;
    color: whitesmoke;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-col-center {
    @include flex-center;
    flex-direction: column;
}

@mixin flex-spread {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
}

@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin absolute-full-screen {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@keyframes flashing {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }

   @mixin input-flashing {
    animation: flashing 1s linear infinite;
  }