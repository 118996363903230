.reposition-tray-container {
  margin: auto 0;
  display: flex;

  .vertical-position-info {
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    h5{
      font-size: 2.5rem;
      font-weight: 600;
    }
  }

  .tray-slot-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    direction: ltr;
    grid-gap: 8px;
    width: 100%;

    .slot-unit {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: $primary-color;
      color: $text-color;
      font-size: 2rem;
      height: 80px;
      border: 1px solid lighten($primary-color, 7);
      margin-bottom: 10px;

      .tray-name-container {
        .tray-name {
          top: 0px;
          left: 5px;
          position: absolute;
          font-size: 1.2rem;
        }
      }

      .plant-icon {
        font-size: 2.5rem;
        color: lightgrey;
        margin-bottom: 5px;

        &[data-available='false'] {
          color: forestgreen;
        }
      }

      .slot-number {
        font-size: 1rem;
        position: absolute;
        bottom: 2px;
        right: 5px;
      }

      &[data-selected='true'] {
        background: lighten($primary-color, 20);
      }
    }
  }
}
