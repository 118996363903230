.confirmation-window {
  .confirm-wrapper {
    display: flex;
    padding: 0.6rem;
    align-items: flex-end;
    justify-content: center;

    h1 {
      font-size: 1.8rem;
    }
    .confirm-btn-container {
      display: flex;
      justify-content: space-evenly;

      .btn {
        margin-left: 2rem;
        max-width: 200px;
      }
    }
  }

  .summary-container {
    &-header {
      display: flex;
      justify-content: space-between;
      border-top: 2px solid $primary-color;
      padding: 1rem;
    }
    &-body {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      .source-summary {
        width: fit-content;
        height: fit-content;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        direction: rtl;
        .summary-unit {
          border: 1px solid $text-color;
          height: 20px;
          width: 30px;
          font-size: 12px;
          color: $text-color;
          text-align: center;
          background: $primary-color;
          &[data-selected='true'] {
            background: forestgreen;
          }
        }
      }

      .summary-details {
        background: white;
        padding: 0 3rem;
        width: 100%;
        &:nth-child(1) {
          background-color: #c51414;
        }

        &-row {
          border-bottom: 2px solid $primary-color;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;

          .info-row-title {
            margin-right: 10px;
          }

          .info-row-detail {
            font-style: italic;
          }
        }
      }

      .destination-summary {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 120px;
        overflow-x: auto;

        .panel-selection-container-row {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 5px;
          height: 3rem;

          .confirmation-panel-block {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primary-color;
            background: $primary-color;
            height: 100%;
            width: 58px;
            border: 1px solid $text-color;
            color: whitesmoke;
            text-align: center;

            .confirm-block-icon {
              color: forestgreen;
            }

            &[data-selected='true'] {
              background: forestgreen;
            }
          }
        }
      }
    }
  }
}
