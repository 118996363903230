.marketing-user-start-process-container {
    text-align: center;

    .lock-settings {
        font-size: 100px;
        color: #707070;
        margin-bottom: 20px;
    }

    .no-access-text {
        font-size: 24px;
    }
}