.tenant-user-management-list {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;

    @media only screen and (min-width: 460px) {
        width: 460px;
    }

    @media only screen and (min-width: 910px) {
        width: 910px;
    }

    @media only screen and (min-width: 1370px) {
        width: 1370px;
    }

    .tenant-user-management-adding {
        width: 450px;
        height: 292px;
        background: #464646 0% 0% no-repeat padding-box;
        border-radius: 6px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            top: 126px;
            left: 210px;
            width: 30px;
            height: 30px;
            font-size: 34px;
            color: #F5F5F5;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}