.sweden-ui-number-pad {
  margin: auto;
  padding: 1.5rem;
  background: $primary-color;
  border-radius: 10px;
  touch-action: manipulation;
  max-width: 600px;

  .display-section {
    .screen-and-backspace {
      display: flex;
      touch-action: manipulation;

      .sweden-ui-number-pad-display {
        display: flex;
        background-color: $secondary-color;
        text-align: right;
        color: antiquewhite;
        font-weight: bold;
        font-size: 3em;
        padding: 2px 12px;
        width: 100%;
        flex: 2;
        touch-action: manipulation;
      }

      .btn {
        max-width: 200px;
        border: 1px solid $text-color;
        font-size: 1.8rem;
        padding: 1rem;
        width: 80px;
        margin-left: 1.5rem;
        transition: 80ms all ease-out;
        touch-action: manipulation;
      }
    }
  }

  .numbers {
    margin-top: 0.7rem;
    touch-action: manipulation;

    .numpad-btn-group {
      display: flex;
      touch-action: manipulation;

      &:first-of-type {
          margin-top: 1.5rem;
          touch-action: manipulation;
      }

      &:not(:last-of-type) {
        margin-bottom: 1.5rem;
        touch-action: manipulation;
      }
      .btn {
        margin: 0 1.5rem 0 0;
        border: 1px solid $text-color;
        font-size: 1.8rem;
        padding: 1rem;
        height: 80px;
        transition: 80ms all ease-out;
        touch-action: manipulation;

        &:nth-of-type(5n) {
            margin: 0;
            touch-action: manipulation;
        }
      }
    }
  }
}

@media (max-width: 900px) {
}
