.boolean {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0;

  .yes {
    width: 280px;
    margin-right: 20px;
    border-radius: 15px;
    // background-color: forestgreen;
    height: 180px;
  }
  .no {
    width: 280px;
    border-radius: 15px;
    // background-color: #c51414;
    height: 180px;
  }
}

.bool-info {
  margin-bottom: 50px;
}
