.weighing-tray-container {
    display: inline-block;

    .transplanted-tray {
        display: inline-grid;
        grid-template-columns: repeat(10, 35px);
        grid-gap: 2px;
        direction: rtl;
        margin-left: 50px;

        .tray-item {
            height: 20px;
        }
    }
}

#harvesting-container:has(div.scale-tray) {
    display: flex;
}