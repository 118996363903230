.scale{
    width: 350px;
    position: relative;
    margin: 1rem auto 0 auto;

    .head{
        display: flex;
        flex-direction: column;
        align-items: center;

        .plant {
            position: relative;
            i{
                color: forestgreen;
                font-size: 60px;
                transform: translateY(10px);
            }

            .plant-icon-text{
                position: absolute;
                top: 50%;
                right: -100%;
                font-size: 1.3rem;
                font-weight: 600;
            }
        }

        .top{
            width: 100%;
            height: 10px;
            background: $primary-color;
            position: relative;

            &::before,
            &::after{
                content: '';
                position: absolute;
                bottom: 0;
                height: 20px;
                left: -20px;
                width: 20px;
                background: $primary-color;
            }

            &::after{
                left: unset;
                right: -20px;
            }
        }

        .bottom {
            height: 20px;
            width: 45px;
            background: darken($primary-color, 10);
            margin: auto;
        }
    }

    .body{
        position: relative;
        background: $primary-color;
        color: $text-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: .7rem;
        border-radius: 10px 10px 0 0;

        .screen {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            font-family: monospace;
            height: 60px;
            width: 65%;
            margin-bottom: 1.5rem;
            background: lighten($primary-color, 10);
            border-radius: 10px;
            box-shadow: inset 0 0 4px 1px darken($primary-color, 2);
        }
        
        .confirm-button{
            border-radius: 10px;
            outline: none;
            border: none;
            color: $text-color;
            background: darken($primary-color, 10);
            cursor: pointer;
            text-align: center;
            height: 40px;
            width: 65%;
            font-size: 1.2rem;
            font-weight: 600;
            padding: 0.7rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        &::after{
            content: '';
            height: 10px;
            width: 100%;
            margin: 10px auto 0 auto;
            background: linear-gradient(grey, darken(grey, 5), grey);
            filter: blur(5px);
            position: absolute;
            bottom: -7px;
            left: 50%;
            transform: translateX(-50%);

        }
    }
}

.scale-tray {
    display: block;
    width: 350px;
    position: relative;
    margin: auto auto auto auto;

    .head{
        display: flex;
        flex-direction: column;
        align-items: center;

        .plant {
            position: relative;
            i{
                color: forestgreen;
                font-size: 60px;
                transform: translateY(10px);
            }

            .plant-icon-text{
                position: absolute;
                top: 50%;
                right: -100%;
                font-size: 1.3rem;
                font-weight: 600;
            }
        }

        .top{
            width: 100%;
            height: 10px;
            background: $primary-color;
            position: relative;

            &::before,
            &::after{
                content: '';
                position: absolute;
                bottom: 0;
                height: 20px;
                left: -20px;
                width: 20px;
                background: $primary-color;
            }

            &::after{
                left: unset;
                right: -20px;
            }
        }

        .bottom {
            height: 20px;
            width: 45px;
            background: darken($primary-color, 10);
            margin: auto;
        }
    }

    .body{
        position: relative;
        background: $primary-color;
        color: $text-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: .7rem;
        border-radius: 10px 10px 0 0;

        .screen {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            font-family: monospace;
            height: 60px;
            width: 65%;
            margin-bottom: 1.5rem;
            background: lighten($primary-color, 10);
            border-radius: 10px;
            box-shadow: inset 0 0 4px 1px darken($primary-color, 2);
        }
        
        .confirm-button{
            border-radius: 10px;
            outline: none;
            border: none;
            color: $text-color;
            background: darken($primary-color, 10);
            cursor: pointer;
            text-align: center;
            height: 40px;
            width: 65%;
            font-size: 1.2rem;
            font-weight: 600;
            padding: 0.7rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        &::after{
            content: '';
            height: 10px;
            width: 100%;
            margin: 10px auto 0 auto;
            background: linear-gradient(grey, darken(grey, 5), grey);
            filter: blur(5px);
            position: absolute;
            bottom: -7px;
            left: 50%;
            transform: translateX(-50%);

        }
    }
}