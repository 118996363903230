.weighing-panel-container{

    .panel-selection-container-row{
        .plant-block{
            margin: 2px;
            font-size: 1.6rem;
            height: 55px;
            position: relative;

            .plant-name {
                @include plant-name;
            }
        }
    }
}